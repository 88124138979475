.social-icons {
  display: flex;
  gap: 15px;
  list-style: none;
  padding: 0;
  justify-content: center;
}

.social-icons li {
  position: relative;
  display: inline-block;
  width: 50px; /* Фиксированная ширина для выравнивания */
}

.social-icons a {
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center; /* Центрирование иконок */
  font-size: 16px;
  position: relative;
}

.social-icons img {
  width: 40px;
  height: 40px;
}

.social-icons span {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute;
  bottom: -25px; /* Отображать текст ниже иконки */
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.social-icons a:hover span {
  visibility: visible;
  opacity: 1;
}

.disclaimer {
  font-size: 14px;
  text-align: center;
}