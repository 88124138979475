.orderList{

    &__wrapper{
      width: 100%;
      max-width: 450px;
      text-align: center;
      margin-bottom: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 64px;

      @media(max-width:767px){
        display: none;
        //left: -18px;
        //width: calc(100% + 36px);
        //max-width: 400px;
        //filter: drop-shadow(0px 10px 50px rgba(0, 0, 0, 0.25));
      }
     }
}

.navigatonWrapper{
  margin-top: 24px;
  justify-content: center;
  align-items: center;
  display: none;
  @media(max-width:640px){
    display: flex;
  }

}

.paggination{
  font-family: "Rounded Mplus 1c", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.1em;
  color: rgba(54, 54, 54, 0.68);
  width: 100px;
  display: flex;
  justify-content: center;
}

.pagginationActive{
  color: #000000;
  font-size: 31px;
}

.orderItem{
  border-radius: 5px;
  overflow: hidden;
  img{
    //border: 3px solid #ffffff;
    width: 100%;
    height: auto;
    min-width: 100%;
    object-fit: contain;
    border-radius: 5px;

    @media(max-width:640px){
      //filter: drop-shadow(0px 10px 50px rgba(0, 0, 0, 0.25));
    }
  }
}

.orderItemActive{
  filter: drop-shadow(0px 10px 9px rgba(0, 0, 0, 0.2));
  img{
    border: 3px solid #69C9A6;

    border-radius: 5px;
    @media(max-width:640px){
      border: none;
    }

  }
}

.nextBtn, .preBtn{
  cursor: pointer;
  background: #FE8496;
  border-radius: 35px;
  color: white;
  width: 44px;
  height: 44px;
  box-shadow: none;
  padding: 0;
  min-width: 44px;
  transition: all 0.3s ease;
  font-size: 1.6em;
  border: none;
  line-height: 50px;
  align-self: center;
  outline-color: transparent;
  font-family: "Futura PT", sans-serif;
  font-style: normal;
  font-weight: bold;
  margin: 0 26px;
  position: relative;

  i.book-step{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    width: 13px;
  }
}

.preBtn, .nextBtn{
  &:after{
    content: "";
    position: absolute;
    width: 20px;
    height: 16px;
    display: block;
    background: url("../../../assets/images/book-step-arrow-left.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

}
}

.nextBtn {
  transform: rotate(180deg);
}