.gender_section__content {
  .gender_section__info.see-the-book {
    .error-validation {
      border: 2px solid #FE8496;
    }

    .error-validation-name {
      display: block;

      @media (max-width: 815px) {
        bottom: 5px;
        right: -33px;
      }
    }
  }

  &.book-content {
    @media (min-width: 1101px) and (max-width: 1399px) {
      margin-top: -40px;

      .btns-block.book-mobile.book-step.constructor-btn {
        margin-top: 80px;
      }
    }
  }
}

.gender_section {
  .gender_section__form {
    display: flex;
    flex-direction: column;
    width: 865px;

    @media (max-width: 991px) {
      max-width: 865px;
      width: 98%;
    }

    @media (max-width: 815px) {
      width: 95%;
      margin-bottom: 72px;
    }

    @media (max-width: 360px) {
      width: 98%;
    }

    @media (min-width: 1101px) and (max-width: 1399px) {
      width: 600px;
    }

    .see-the-book {
      width: auto;
      height: auto;
      margin: 48px 0 43px;

      @media (max-width: 815px) {
        margin: 0 auto 40px;
        width: 340px;
      }

      @media (max-width: 360px) {
        width: 98%;
      }

      @media (min-width: 1101px) and (max-width: 1399px) {
        margin: 10px 0 10px;
      }

      &__input-name {
        width: 508px;

        @media (max-width: 815px) {
          width: 340px;
        }

        @media (max-width: 360px) {
          width: 98%;
        }
        @media (min-width: 1101px) and (max-width: 1399px) {
          width: 370px;
          height: 42px;
        }
      }

      .field__wrapper {
        @media (max-width: 815px) {
          width: 100%;
        }
      }

      &__toggler {
        margin: 0 0 0 17px;

        @media (max-width: 815px) {
          margin: 0;
        }

        @media (min-width: 321px) and (max-width: 815px) {
          width: 340px;

          .toggle-button {
            width: 340px;

            &:after {
              width: 179px;
            }

            &:checked::after {
              transform: translateX(calc(89% + 2px));
            }
          }
        }

        @media (min-width: 1101px) and (max-width: 1399px) {
          margin-top: 16px;
          .toggle-button{
            height: 43px;
            width: 210px;
          }
          .toggle-button:after{
            height: 43px;
            width: 110px;
          }
          span{
            top: 7px;
          }
          &__boy{
            font-size: 14px;
            left: 10px;
          }
          &__girl{
            right: 15px;
            font-size: 14px;
            left: 115px;
          }
          .girl-sign{
            width: 41px;
          }
          .boy-sign{
            width: 31px;
          }
        }
      }
    }
  }

  &__info {
    box-shadow: none;
    margin-bottom: 3px;

    @media (max-width: 815px) {
      margin-bottom: 42px;
      margin-right: 0;
    }

    @media (min-width: 1101px) and (max-width: 1399px) {

    }

    &__name {
      width: 508px;
      position: relative;
    }

    &__name.error-validation {
      border: 2px solid #FE8496;
    }

    .error-validation-name {
      display: none;
      position: absolute;
      width: 100%;
      bottom: -23px;
      right: -55px;
      font-family: $futura-pt-font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #FE8496;
    }
  }

  &__image-block.noactive {
    display: none;
  }

  &__image-block {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    margin-bottom: 43px;

    @media (max-width: 991px) {
      // width:93%;
      // margin: 0 auto;
      margin-bottom: 43px;
    }

    @media (max-width: 815px) {
      order: -1;
      margin-bottom: 13px;
    }

    &__wrapper {
      width: 264px;
      height: 337px;
      border: 2px dashed $dash-color;
      border-radius: 10px;
      cursor: pointer;

      @media (max-width: 815px) {
        border: none;
        width: 30%;
        height: 194px;
      }

      @media (max-width: 375px) {
        border: none;
        width: 32%;
        height: 194px;
      }

      @media (min-width: 1101px) and (max-width: 1399px) {
        width: 164px;
        height: 191px;
        margin-bottom: -15px;
      }
    }

    &__wrapper.active {
      background: $background-active-color;
      border: 2px dashed #FE8496;
      border-radius: 10px;

      @media (max-width: 815px) {
        border: none;
      }
    }

    .gender_section__image {
      max-width: 100%;
      height: 100%;

      @media (max-width: 815px) {
        height: 194px;
      }

    }

    .gender_section__image.blond-girl {
      background: url("../images/gender-blond-girl.svg") no-repeat center 0;
      background-position-y: center;

      @media (max-width: 815px) {
        background-size: contain;
        background-position: center;
        max-width: 93%;
      }

      @media (min-width: 1101px) and (max-width: 1399px) {
        background-size: contain;
        background-position: center;
        height: 191px;
      }
    }

    .gender_section__image.black-girl {
      background: url("../images/gender-black-girl.svg") no-repeat center 0;
      background-position-y: center;

      @media (max-width: 815px) {
        background-size: contain;
        background-position: center;
        max-width: 92%;
      }
      @media (min-width: 1101px) and (max-width: 1399px) {
        background-size: contain;
        background-position: center;
        height: 191px;
      }

    }

    .gender_section__image.hair-3-girl {
      background: url("../images/gender-hair-3.svg") no-repeat center 0;
      background-position-y: center;

      @media (max-width: 815px) {
        background-size: contain;
        background-position: center;
      }
      @media (min-width: 1101px) and (max-width: 1399px) {
        background-size: contain;
        background-position: center;
        height: 191px;
      }
    }

    .gender_section__image.boy-blond {
      background: url("../images/boy-blond.svg") no-repeat center 0;
      background-position-y: center;

      @media (max-width: 815px) {
        background-size: contain;
        background-position: center;
      }


      @media (max-width: 480px) {
        background-size: 120%;
      }
      @media (max-width: 400px) {
        background-size: 121%;
      }
      @media (min-width: 1101px) and (max-width: 1399px) {
        background-size: 92%;
        background-position: center;
        height: 191px;
        background-position-y: -15px;
      }
    }

    .gender_section__image.boy-black {
      background: url("../images/boy-black.svg") no-repeat center 0;
      background-position-y: center;

      @media (max-width: 815px) {
        background-size: contain;
        background-position: center;
      }

      @media (max-width: 375px) {
        //background-position-x: 9px;
      }
      @media (min-width: 1101px) and (max-width: 1399px) {
        background-size: 74%;
        background-position: 65%;
        background-position-y: -12px;
        height: 191px;
      }
    }

    .gender_section__image.boy-3 {
      background: url("../images/boy-3.svg") no-repeat center 0;
      background-position-y: center;

      @media (max-width: 815px) {
        background-size: contain;
        background-position: center;
      }
      @media (min-width: 1101px) and (max-width: 1399px) {
        background-size: 70%;
        background-position: center;
        background-position-y: -15px;
        height: 191px;
      }
    }
  }
}

.constructor-btn{
  padding: 17px 21px;
  min-width: 168px;

  @media(max-width: 812px){
    padding: 20px 32px;
  }

  //@media (min-width: 1101px) and (max-width: 1399px) {
  //  padding: 5px 15px;
  //}

  .white-arrow-right{
    //margin-left: 2px;
  }
}
