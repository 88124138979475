@mixin title {
  font-family: $futura-pt-font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #363636;
}


.order-step {
  //display: flex;

  @media(max-width: 1975px) {
    margin-bottom: 50px;
  }

  @media (max-width: 815px) {
    margin-bottom: 106px;
  }

  @media (max-width: 767px) {
    margin-bottom: 148px;
  }

  input {
    color: #000;
  }


  //@media (min-width: 768px) and (max-width: 975px) {
  //  width: 65%;
  //}

  h1 {
    display: none;
    @include title;
    line-height: 28px;
    text-align: center;
  }

  .main-form {
    display: flex;
    flex-direction: column;
    width: 62%;

    @media (max-width: 975px) {
      width: 100%;
    }

    .field__wrapper {
      position: relative;
      //width:100%;

      @media (max-width: 975px) {
        width: 100%;
      }

      .customer-info__name, .customer-info__comment {
        //width: 76%;

        @media (max-width: 975px) {
          width: 100%;
          margin-top: 5px;
          padding-top: 0;
          margin-bottom: 13px;
        }

      }

      .customer-info__comment {
        width: 554px;
        padding-top: 19px;

        border: 2px solid #E5E5E5;
        box-sizing: border-box;
        border-radius: 35px;
        resize: none;
        outline: none;

        @media (max-width: 975px) {
          height: 90px;
          margin-bottom: 0;
          width: 100%;
        }
      }
    }

    .field__wrapper.comment{
      // margin-top: -12px;
    }

    .success-validation-name {
      position: absolute;
      width: 100%;
      bottom: 5px;
      right: -29px;
      font-family: $futura-pt-font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #69C9A6; 
      @media (max-width: 975px) {
        bottom: -3px;
      }
    }
    .success-validation-name.promo {
      bottom: 5px;
    }
    .error-validation-name {
      position: absolute;
      width: 100%;
      bottom: 5px;
      right: -29px;
      font-family: $futura-pt-font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #FE8496;
      

      @media (max-width: 975px) {
        bottom: -3px;
      }
    }

    .error-validation-name.contact {

      @media (max-width: 975px) {
        bottom: 7px;
      }
    }

    .error-validation-name.contact.email {

      @media (max-width: 975px) {
        bottom: -4px;
      }
    }

    .error-validation-name.contact.address {
      bottom: -18px;
      @media (max-width: 975px) {
        bottom: -12px;
      }
    }

    .error-validation-name.promo {
      bottom: 5px;
    }

    &__title {
      @include title;
      margin-top: 34px;

      @media (min-width: 975px) {
        margin-bottom: 0;
        margin-top: 87px;
      }
    }

    &__title.delivery {
      margin-top: 20px;

      @media (min-width: 975px) {
        margin-top: 34px;
        margin-bottom: 25px;
      }
    }

    &__title.payment {
      margin-top: 20px;

      @media (min-width: 975px) {
        margin-top: 30px;
        margin-bottom: 25px;
      }
    }

    .see-the-book__input-name {
      padding-left: 35px;
    }

    .customer-info__contacts-wrapper {

      @media (max-width: 975px) {
        width: 100%;
      }
    }

    .customer-info__name {
      width: 554px;
      margin: 25px 0;

      @media (min-width: 1200px) {
        margin-right: 52px
      }

    }

    .customer-info__name.error-validation, .customer-info__promo.error-validation {
      border: 2px solid #FE8496;
    }


    .customer-info__contacts {
      width: 268px;
      margin-left: 0;
      margin-right: 18px;
      margin-bottom: 25px;

      @media (max-width: 975px) {
        width: 100%;
        margin-bottom: 23px;
      }
    }

    .customer-info__contacts.see-the-book__input-name.email{
      @media (max-width: 975px) {
        margin-top: -10px;
        margin-bottom: 13px;
      }
    }

    .customer-info__contacts.error-validation {
      border: 2px solid #FE8496;
    }

    .customer-info__promo {
      width: 554px;
      margin: 0 0 25px;

      @media (max-width: 975px) {
        width: 100%;
        margin-bottom: 23px;
        margin-top: 0;
      }
    }

    .promo__wrapper {
      position: relative;

      .promocode__wrapper {
        position: relative;
        @media (max-width: 975px) {
          width: 100%;
        }
      }
    }

    .promo-btn {
      position: absolute;
      top: 0;
      width: 180px;
      height: 60px;
      background: #48C8F0;
      //right: 6.5%;
      right: 0;

      @media (max-width: 975px) {
        top: 0px;
        width: 134px;
      }
      &:active{
        box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.25);
      }

    }

    .customer-info__comment {
      margin-left: 0;
    }

    .delivery__container {
      position: relative;

      &-info {
        position: relative;

      }
    }

    .customer-info__city-info {
      display: flex;
      margin: 0;
      position: absolute;
      z-index: 9;
      top: 65px;

      @media (max-width: 975px) {
        flex-direction: column;
        width: 100%;
        margin-top: 2px;
      }
      @media (min-width: 975px) {
        top: 80px;
      }
    }

    //select
    .selected-box {
      display: flex;
      flex-direction: column;
      width: 268px;
      //box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
      border-radius: 30px;
      overflow: hidden;
      padding: 0;
      margin-right: 18px;
      border: 2px solid #E5E5E5;
      box-sizing: border-box;
      height: fit-content;

      @media (max-width: 975px) {
        z-index: 13;
        width: 100%;
        background-color: white;
      }

      .options-container {
        font-family: $futura-pt-font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: $main-text-color;
        opacity: 0;
        max-height: 0;
        width: 100%;
        background: #FFFFFF;
        transition: all 0.4s;
        overflow: hidden;
        order: 1;
      }

      .options-container.active {
        max-height: 142px;
        min-width: 100%;
        opacity: 1;
        overflow-y: scroll;
        position: relative;
        margin-bottom: 5px;
      }

      .options-container::-webkit-scrollbar {
        width: 9px;
        background: white;
        border-radius: 0 30px 30px 0;
        opacity: 0.5;
      }

      .options-container::-webkit-scrollbar-thumb {
        background: #E5E5E5;
      }

      .option, .selected {
        padding: 20px 45px 20px 35px;
        cursor: pointer;
      }

      .option {
        padding: 12px 48px 11px 35px;
      }

      .option .radio {
        display: none;
      }

      .option:hover {
        background: #FFF7DB;
      }

      label {
        cursor: pointer;
      }

      .selected {
        height: 60px;
        color: #363636;
        opacity: 0.7;
        order: 0;
        position: relative;

        font-family: $futura-pt-font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .selected::after {
        content: "";
        background: url("../images/order-arrow.svg");
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        height: 100%;
        width: 17px;
        right: 20px;
        top: 40%;
        transition: all 0.4s;
      }
    }

    .selected-box.reg {
      @media (max-width: 975px) {
        position: absolute;
        top: 88px;
        z-index: 12;
      }
      @media (max-width: 380px) {
        position: absolute;
        top: 74px;
        z-index: 12;
      }
    }

    .delivery__wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-top: 120px;

      @media (max-width: 975px) {
        display: none;
      }

    }

    .delivery-info-mobile {
      display: none;
      @media (max-width: 975px) {
        width: 372px;
        margin: 177px auto 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 0;
      }
      @media (max-width: 375px) {
        margin-left: -14px;
        margin-top: 181px;
      }
      @media (max-width: 320px) {
        margin-left: -32px;
      }
    }

    .see-the-book__toggler--three-positions {
      @media (max-width: 375px) {
        margin-left: 0;
      }
    }

    .form_radio_btn {
      display: inline-block;
      margin-right: 10px;
    }

    .form_radio_btn input[type=radio] {
      display: none;
    }

    .form_radio_btn label {
      width: 261px;
      height: 84px;
      border: 2px dashed #E5E5E5;
      border-radius: 10px;
      margin-right: 22px;
      margin-bottom: 40px;
      text-align: center;
      position: relative;
      cursor: pointer;
      user-select: none;
      font-family: $futura-pt-font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #363636;
    }

    .delivery__container {
      padding-top: 26px;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 7;
    }

    /* Checked */
    .form_radio_btn input[type=radio]:checked + label {
      background: #FFF7DB;
      border: 2px dashed #FE8496;

      .delivery__container {
        background: #FFF7DB;
      }
    }

    .form_radio_btn input[type=radio]:checked + label:before {
      position: absolute;
      content: "";
      right: 112px;
      top: 54px;
      transform: scaleY(1.7) rotate(45deg);
      width: 30px;
      height: 30px;
      background: #FFF7DB;
      border: 2px dashed #FE8496;
      border-radius: 5px;
    }

    .delivery__info {
      margin: 26px 0 30px 0;
      display: flex;

      @media (min-width: 975px) {
        margin-top: 0;
        width: 554px;
      }

      &__title {
        font-family: $futura-pt-font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: #363636;
        white-space: nowrap;

        @media (max-width: 975px) {
          white-space: normal;
        }
      }

      .dashed {
        border-bottom: 1px dashed #E5E5E5;
        width: 100%;
      }

      &__price {
        font-family: $futura-pt-font-family;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        color: #363636;
        white-space: nowrap;

        @media (max-width: 975px) {
          align-self: flex-end;
        }
      }

    }

    .address-block {
      width: 554px;
      height: 104px;
      border: 2px solid #E5E5E5;
      box-sizing: border-box;
      border-radius: 35px;
      resize: none;
      outline: none;
      padding-left: 35px;
      padding-top: 20px;

      font-family: $futura-pt-font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      opacity: 0.7;

      @media (max-width: 975px) {
        width: 100%;
      }
    }

    .address-block.error-validation {
      border: 2px solid #FE8496;
    }

    .payment {
      display: flex;

      @media (max-width: 650px) {
        flex-direction: column;
      }

    }

    .form_radio_btn_payment {
      display: inline-block;
      margin-right: 10px;

      @media (max-width: 975px) {
        margin-bottom: 23px;
        margin-right: 0;
      }
      @media (min-width: 576px) and (max-width: 650px) {
        // margin-left: 25%;
      }

    }

    .form_radio_btn_payment input[type=radio] {
      display: none;
    }

    .form_radio_btn_payment label {
      width: 268px;
      height: 84px;
      border: 2px dashed #E5E5E5;
      border-radius: 10px;
      margin-right: 18px;
      display: flex;
      align-items: center;
      font-family: $futura-pt-font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #363636;
      cursor: pointer;
      @media (max-width: 375px) {
        width: 100%;
      }


      .step {
        border-radius: 50%;
        background: #FFFFFF;
        border: 2px solid #C4C4C4;
        width: 30px;
        height: 30px;
        margin-right: 12px;
        margin-left: 19px;
      }
    }


    /* Checked */
    .form_radio_btn_payment input[type=radio]:checked + label {
      background: #FFF7DB;
      border: 2px dashed #FE8496;

      .step {
        background: #69C9A6;
        border: 2px solid #69C9A6;
        padding: 0;
      }

      .step:after {
        content: "";
        background: url("../images/checked-pay.svg");
        width: 30px;
        height: 30px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    @media (max-width: 1600px) {
      .promo__wrapper .promo-btn {
        //right: 21.5%;
      }
    }
    @media (max-width: 975px) {
      .promo__wrapper .promo-btn {
        right: 0;
      }
    }

    @media (min-width: 1200px) and (max-width: 1400px) {
      //.customer-info__contacts-wrapper .field__wrapper:nth-child(1) {
      //  margin-bottom: 25px;
      //}

      .promo__wrapper .promo-btn {
        //right: 6.5%;
      }
    }
  }
  p.main-form__title{
    @media (max-width: 812px) {
      margin-top: 27px;
    }
  }

  p.main-form__title.delivery{
    @media (max-width: 812px) {
      margin-top: 24px;
    }
  }

  .check__wrapper {
    align-self: flex-start;
    position: sticky;
    top: 80px;
    margin-top: 84px;
  }

  .check__delivery-info {
    width: 100%;
    max-width: 374px;
    padding: 10px 20px;
    font-family: "Futura PT",sans-serif;
    font-size: 14px;
    color: #f00;
  }

  .check {
    width: 374px;
    height: fit-content;
    padding-bottom: 45px;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    border-radius: 15px;

    @media (max-width: 375px) {
      width: 100%;
      margin-top: 69px;
    }

    &__cover-img {
      position: absolute;
      top: -45px;
      left: 80px;

      width: 213px;
      height: 154px;
      filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.15));
      border-radius: 15px;

      @media (max-width: 375px) {
        left: 59px;
      }
      @media (min-width: 378px) and (max-width: 975px) {
        left: 25%;
      }

      @media (max-width: 320px) {
        left: 12%;
      }
    }

    .check__info__wrapper {
      display: flex;
      flex-direction: column;
      //margin-top: 100px;
      padding: 41px 32px 0 39px;

      @media (max-width: 376px) {
        padding: 40px 23px;
        padding-bottom: 0;
      }


      button {
        margin-top: 12px;
        width: 296px;
        height: 60px;
        background: #69C9A6;
        font-weight: 700;

        &:active{
          box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.25);
        }

        @media (max-width: 376px) {
          position: relative;
          width: 162px;
          right: -117px;
          padding: 15px 14px;
        }
        @media (min-width: 376px) and (max-width: 975px) {
          position: relative;
          width: 170px;
          left: 50%;
          padding: 15px 14px;
        }
        @media (max-width: 320px) {
          right: -92px;
          width: 150px;
        }


      }
    }

    &__info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 13px;

      &__title {
        font-family: Futura PT;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: #000000;
        white-space: nowrap;
      }

      &__title.sum {
        font-weight: 700;
      }

      .dashed {
        position: relative;
        top: -6px;
        width: 100%;
        border-bottom: 1px dashed #E5E5E5;
      }

      .delivery__info__price {
        white-space: nowrap;

        &.red {
          color: red;
          font-weight: 500;
        }

        &.sum{
          font-weight: 700
        }
      }
    }

    .term {
      margin-top: 21px;
      font-family: Futura PT;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #363636;
      opacity: 0.7;

      @media (max-width: 375px) {
        //width: 312px;
        //margin-left: -31px;
      }
      @media (min-width: 376px) and (max-width: 975px) {
        width: 97%;
      }
      @media (max-width: 320px) {
        //width: 275px;
        margin-left: 0;
      }



      div {
        @media (max-width: 370px) {
          display: inline;
          width: 100%;
          letter-spacing: -0.05px;
        }
      }

      span {
        color: #363636;
        text-decoration: underline;
        white-space: nowrap;
        cursor: pointer;

        @media (max-width: 370px) {
          white-space: normal;
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  h1 {
    display: block;
  }
  .order-step {
    width: 100%;
    margin-top: 0;

  }
}

.btn-back__wrapper {
  display: flex;
  justify-content: flex-start;
  width: 51%;
  margin-bottom: 50px;
  z-index: 10;

  @media (max-width: 975px) {
    display: none;
  }

  @media (max-width: 1680px) {
    width: 975px;
    align-self: flex-start;
    margin: 0 auto;
    margin-bottom: 50px;
  }
  @media (max-width: 1680px) {
    width: 975px;
    align-self: flex-start;
    margin: 0 auto;
    margin-bottom: 50px;
  }

  button {
    margin-top: 50px;
  }

  @media (max-width: 380px) {
    margin-bottom: 31px;
  }
}

.delivery-info-mobile.see-the-book__toggler .toggle-button:checked::after,
.delivery-info-mobile.see-the-book__toggler .toggle-button::after {
  background-color: #69C9A6;
  color: #ffffff;
  border: none;
}

.see-the-book__toggler .cover-is-chosen__toggler {

  &.active > span {
    color: white;
    opacity: 1;
  }

  > span {
    width: 106px;
    //text-align: center;
    //left: 26px;

  }

  // &.see-the-book__toggler__boy {
  //   @media (min-width: 381px) and (max-width: 450px) {
  //     left: 43px;
  //   }
  // }

  &.see-the-book__toggler__girl {
    right: 25px;
    // @media (max-width: 380px) {
    //   right: 25px;
    // }

    // @media (min-width: 381px) and (max-width: 450px) {
    //   right: 25px;
    // }
  }

  &__first-line {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    left: 33px;
    top: -6px !important;

    @media (max-width: 975px) {
      text-align: center;
      left: 27px;
    }

  }

  &__first-line_uk {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    left: 33px;
    top: -13px !important;

    @media (max-width: 380px) {
      // left: 47px;
    }
  }

  &__second-line {
    font-size: 14px;
    line-height: 18px;

    left: 34px;
    top: 15px !important;

    @media (max-width: 380px) {
      // left: 47px;
    }

  }

  &__hard-first-line {


    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    right: 18px;
    top: -6px !important;

    @media (max-width: 975px) {
      text-align: center;
      //left: 27px;
    }
  }

  &__hard-second-line {
    font-size: 14px;
    line-height: 18px;

    right: -7px;
    top: 15px !important;

    @media (max-width: 380px) {
      right: -20px;
    }
  }
}

.white-arrow-left.order{

  @media(max-width: 812px){
    // position: absolute;
    // left: 13px;
    // z-index: 1;
  }

}

.discont{
  margin-left: 10px;
  color: red;
}
.check__info__wrapper{
  .btns-wrapper.order-mobile{

    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;


    @media (max-width: 500px) {
      column-gap: 31px;
      justify-content: flex-end;
    }

    button.btn-icon.btn-white.order-back-btn{
      display: none;
      @media (max-width: 975px) {
        display: flex;
        flex-wrap: nowrap;
        background: transparent;
        left: 0;
        top: 0;
        margin: 0;
        padding: 0;
        right: 0;
        width: max-content;
        bottom: auto;

        &:active{
          box-shadow:none;
        }

      }
    }

    .order-btn{
      @media (max-width: 975px) {
        left: 0;
        margin-top: 0;
      }

    }
  }
}

.ordersTitle{
  font-family: 'Futura PT', sans-serif;
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.404rem;
  margin: 65px 0 24px 0;

  @media (max-width: 975px) {
    margin-top: 0;
    text-align: center;
  }

  @media (max-width: 500px) {
    width: 89%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 13px;
  }
}

.add-new-book{
  background: #FFF7DB;
  border: 2px dashed #FE8496;
  border-radius: 10px;

  font-family: 'Futura PT', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  color:#FE8496;
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 19px;

  @media (max-width: 975px) {
    width: 95%;
    margin: 0 auto;
    margin-top: 19px;
  }

  @media (max-width: 500px) {
    width: 92%;
    margin-top: 8px;
  }

  @media (max-width: 350px){
    width: 85%;
  }

  &:hover{
    cursor: pointer;
    opacity: 0.7;
  }
  &:active{
    opacity: 0.8;
  }

  & span{
    position: relative;
    display: flex;
    column-gap: 17px;
    align-items: center;
  }

  & span:after{
    content: '';
    display: block;
    background-image: url("../images/add-new-book.svg");
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 21px;
    height: 21px;

  }

}

.form-checkWrapper{
  display: flex;

  @media (max-width: 975px) {
    display: grid;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    margin-top: -5px;
    width: 95%;

  }
  @media (max-width: 500px) {
    width: 92%;
  }

  @media (max-width: 350px){
    width: 85%;
  }
}

.order-step .main-form .delivery-info-mobile.see-the-book__toggler--three-positions {
  justify-content: space-between;
  width: 100%;
  max-width: none;
  border: 2px solid #ebebeb;
  border-radius: 100px;
} 

.see-the-book__toggler .toggler__box {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.see-the-book__toggler .toggler__input {
  display: none;
}

.see-the-book__toggler .toggler__label {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 100%;
  font-family: "Futura PT",sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.see-the-book__toggler .toggler__text {
  position: relative;
  z-index: 2;
  color: #363636;
  opacity: 0.7;
}

.see-the-book__toggler .toggler__input:checked + .toggler__label .toggler__text {
  color: #fff;
  opacity: 1;
}

.see-the-book__toggler .toggler__input:checked + .toggler__label::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  display: block;
  background-color: #69c9a6;
  border-radius: 100px;
}

.delivery__warning {
  font-family: "Futura PT",sans-serif;
  font-size: 14px;
  color: #f00;
}