
.header {
  height: $header-height-desktop;

  &.constructor-opened {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);

    .header__order-button-link {
      display: none;
    }

    .header__links {
      width: 441px;
      margin: 0;
      a{
        line-height: 1;
      }
    }


  }

  .container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    background: white;
    position: relative;

    @media(max-width:1200px){
      max-width: 1164px;
      padding: 0 20px;
    }

    @media(max-width:450px){
      padding: 0 ;
    }
  }



  &.constructor {
    margin-top: 0;
    padding-bottom: 13px;
    padding-top: 13px;
    max-width: 100%;
  }

  &.modal {
    display: none;

    @media(max-width: 815px) {
      padding: 19px 0 0;
      display: flex;
      min-width: 100%;
      height: 83px;
      min-height: 83px;
      position: absolute;
      top: 16px;
      justify-content: center;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      border-radius: 0;
      overflow: auto;
      //.mobile-menu-button {
      //  display: none;
      //}
      //.header__order-button-mobile {
      //  position: absolute;
      //  right: 7%;
      //  top: 27px;
      //}
    }
  }

  
  .mobile-menu-button, &__order-button-mobile {
    display: none;
    background: white;
    width: 49px;
    height: 30px;
  }

  &__logo {
    height: 74px;
    width: 273px;

    @media(max-width:1200px){
      height: 65px;
    }
  }

  &__links {
    width: 40%;
    // margin: 0 50px;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    @media(max-width:1200px){
      // margin: 0 auto;
      width: 42%;
    }
  }

  &__order-button {
    height: 50px;
    background-color: #48C8F0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width:1200px){
      height: 46px;
    }

  }

  &__order-button:hover{
    box-shadow: 0px 10px 20px #48c8f0;
  }

  &__order-button:active{
    box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.25);
  }

  .header__order-button.constructor {
    display: none;
  }

  @media  (max-width: 815px) {
    height: 72px;

    .mobile-menu-button {
      width: 60px;
      // order: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__order-button-link {
      display: none;
    }

    &__logo {
      width: auto;
      height: 40px;
      order: 2;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0px);
    }

    &__links, &__order-button {
      display: none;
    }

    &__order-button-mobile {
      height: 30px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;

      order: 3;

      i {
        background: url("../images/shopping-cart.svg") no-repeat 0 0;
        min-width: 22px;
        height: 22px;
      }

      &.constructor i {
        background: none;
      }
    }
  }
}


.basket-icon{
  order: 3
}

.header__order-button-mobile {
  height: 30px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  order: 3;

  i {
    background: url("../images/shopping-cart.svg") no-repeat 0 0;
    min-width: 22px;
    height: 22px;
  }

  &.constructor i {
    background: none;
  }
}

header.header.mobile-menu-opened{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}
