.success-step {
  &__text {
    margin-top: 17px;

    &-thanks {
      font-family: $rounded-mplus-font-family;
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      line-height: 54px;
      color: $main-text-color;
      display: block;
      margin: 45px 0 0;

      @media (min-width: 1101px) and (max-width: 1399px){
        margin-top: 60px;
      }
      
      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 28px;
        margin-top: 4px;
      }
    }

    font-family: $futura-pt-font-family;
    font-weight: 500;
    font-size: 24px;
    line-height: 54px;
    width: 707px;
    text-align: center;

    @media (max-width: 767px) {
      white-space: normal;
      font-size: 18px;
      line-height: 30px;
      width: 100%;
      margin-top: 16px;
    }

    &-footer {
      line-height: 30px;
      margin-bottom: 50px;

      @media (max-width: 767px) {
        line-height: 26px;
        margin-bottom: 23px;
      }
    }
    &-footer.service{
      @media (max-width: 767px) {
        margin-top: 0;
      }
    }
  }

  &__text.title{
    margin: 23px 0;
    font-weight: 700;
    font-size: 30px;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 30px;
      margin-top: 24px;
      margin-bottom: 0;
    }

  }

  &.the-book-step {
    margin-bottom: 65px;
    margin-top: 10px;

    @media (max-width:1366px){
      margin-bottom: 110px;
    }
    @media (max-width:767px){
      margin-bottom: 11px;
    }
  }
}

.swiper-container.swiper-container-initialized.swiper-container-horizontal.swiper-container-pointer-events{
  padding-left: 26px;
  padding-bottom: 27px;
  padding-right: 13px;

  @media(max-width:640px){
    padding: 0;
    //padding-right: 15px;
  }
}
.gender_section__content.book-content{
  @media(max-width:640px){
    padding: 0 18px;
  }
}

.the-book-step.success-step{
  .menu-wrapper{
    filter: drop-shadow(0px 10px 50px rgba(0, 0, 0, 0.25));
  }
}
