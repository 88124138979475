
.arrow{
  cursor: pointer;
  background: #FE8496;
  border-radius: 35px;
  color: white;
  width: 44px;
  height: 44px;
  box-shadow: none;
  padding: 0;
  min-width: 44px;
  transition: all 0.3s ease;
  font-size: 1.6em;
  border: none;
  line-height: 50px;
  align-self: center;
  outline-color: transparent;
  font-family: "Futura PT", sans-serif;
  font-style: normal;
  font-weight: bold;
  margin: 0 26px;
  box-shadow: 0 10px 20px #fe8496;
  margin-bottom: 30px;
}

.arrow:disabled{
  cursor: not-allowed;
    background: #DDDDDD;
    border-radius: 35px;
    color: white;
    width: 44px;
    height: 44px;
    box-shadow: none;
    padding: 0;
    min-width: 44px;
    transition: all 0.3s ease;
    font-size: 1.6em;
    border: none;
    line-height: 50px;
    align-self: center;
    outline: none;
    font-family: "Futura PT", sans-serif;
    font-style: normal;
    font-weight: bold;
}