.app.open-menu{
  overflow: hidden;
  position: relative;
}

.modal-open {
  position: fixed;
  width: 100%;
}

.reviews-photo-desktop {
  @media (min-width: 816px) {
    display: block;
  }
  display: none;
}

.reviews-photo-mobile {
  @media (max-width: 815px) {
    display: block;
  }
  display: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $rounded-mplus-font-family;
}

.link {
  cursor: pointer;
}

.link:hover {
  color: #69C9A6;;
}

.link.wrapper{
  line-height: 1;
}

a {
  color: inherit;
  text-decoration: inherit;
}

ul li {
  font-family: $futura-pt-font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  display: inline;
}

textarea {
  color: black;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1208px;
  }
}

.container.position-relative.cloud-block {

  @media (max-width: 570px) {
    overflow: hidden;
  }
}

button {
  font-family: $futura-pt-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: white;
  border-radius: 35px;
  border: none;
  padding: 20px 29px;
  outline: none;

  &:focus {
    outline: none;
  }

  &.btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.btn-pink {
    background: #FE8496;

    &:hover {
      box-shadow: 0 10px 20px #FE8496;
    }

    &:disabled {
      opacity: 1;
      background: #FFB9C3;
    }
    &:active{
      box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.25);
    }
  }

  &.btn-green {
    background: #69C9A6;

    &:hover {
      box-shadow: 0 10px 20px #69C9A6;
    }

    &:disabled {
      opacity: 0.7;
      background: #69C9A6;
      cursor: not-allowed;
      box-shadow: none;
    }
    &:active{
      box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.25);
    }
  }

  &.btn-green.success {
    margin-bottom: 100px;
    margin-top: 20px;

  }

  &.btn-blue {
    background: $button-blue-color;

    &:active{
      box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.25);
    }
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &.btn-white {
    border: 2px solid $dash-color;
    font-family: $futura-pt-font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #363636;
    cursor: pointer;
    background-color: white;
  }

  &.btn-white.order-back-btn {
    @media (max-width: 975px) {
      position: relative;
      width: 136px;

      border: none;
      bottom: 205px;
      right: -20%;
    }

    @media (max-width: 655px) {
      right: -14%;
    }
    @media (max-width: 545px) {
      right: -10%;
    }
    @media (min-width: 768px) and (max-width: 975px) {
      margin-left: 55px;
    }

    @media (max-width: 450px) {
      top: -205px;
      right: -5%;
    }

    @media (max-width: 376px) {
      width: 120px;
      bottom: 170px;
      left: 29px;
      padding-left: 40px
    }
    @media (max-width: 320px) {
      width: 108px;
      top: -221px;
    }
  }

}

.demo-book {
  left: 0;
  transition: left .7s;
  z-index: 20;
}

.demo-book.cover.stf__parent {
  transform-style: preserve-3d;
  left: -25%;
  transition: left .7s;
  z-index: 20;
  width: 519px;
}

.demo-book.cover-back.stf__parent {
  transform-style: preserve-3d;
  left: 25%;
  transition: left .7s;
  z-index: 20;
  width: 519px;
}

.app {
  height: 100vh;
  overflow-x: hidden;
  overflow-anchor: initial;

  .slideout-sidebar {
    background: transparent;
    pointer-events: none;
    top: $header-height-mobile;

    .mobile-menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      pointer-events: all;
      overflow-y: scroll;
      height: calc(100vh - 96px);
      padding: 52px 0 90px;

      ul {
        margin: 0 auto 70px;
        list-style-type: none;
        padding: 0;
        text-align: center;

        li, a li {
          display: block;

          font-family: $futura-pt-font-family;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 26px;
          color: $main-text-color;

          &:not(:last-child) {
            margin-bottom: 30px;
          }
        }

        a:not(:last-child) li {
          margin-bottom: 30px;
        }
      }

      .socials {
        margin-bottom: 37px;
        display: flex;

        img {
          margin: 0 13px;
          width: 50px;
        }
      }
    }
  }

  .overlay {
    background: white;
    top: $header-height-mobile;
  }

  .main-carousel-mobile {

    &.rec-carousel-wrapper {
      display: none;

      @media (min-width: 320px) and (max-width: 815px) {
        display: block;
        margin-bottom: 20px;
        position: relative;
      }

      .rec-slider-container {
        margin: 0;
      }

      .rec-pagination {
        display: flex;
        justify-content: center;
        padding: 15px 0;
        margin: 0;
        align-items: center;
        height: 45px;
        position: absolute;
        bottom: 0;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;

        .rec-dot {
          -webkit-transition: all 250ms ease;
          transition: all 250ms ease;
          box-shadow: none;

          margin: 0 10px;
          padding: 0;
          width: 8px;
          height: 8px;
          background: $dash-color;

          outline: none;
          border-radius: 50%;

          &_active {
            width: 12px;
            height: 12px;
            background: #69C9A6;
          }
        }

        .rec-dot:hover, .rec-dot:focus {
          box-shadow: none;
        }
      }

      .rec-slide-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #FFFCE2;
        width: 100%;

        &__text {
          height: 80px;
          padding: 0 55px;

          font-family: $rounded-mplus-font-family;
          font-style: normal;
          font-weight: 800;
          font-size: 26px;
          line-height: 32px;

          text-align: center;

          color: $main-text-color;
        }

        img {
          width: 100%;
        }
      }
    }
  }
}

.personalized-alphabet {
  display: flex;
  margin-top: 50px;
  margin-bottom: 61px;

  @media (max-width: 815px) {
    display: none;
  }

  @media (max-width: 1100px){
    max-width: 100%;
    margin-top: 37px;
    padding: 0 0 0 20px;
    margin-bottom: 75px;
  }

  @media (min-width: 1101px) and (max-width: 1399px) {
    margin-top: 0px;
    margin-bottom: 75px;
    padding: 0 0 0 20px;
  }

  &__left {
    margin: 60px 65px 60px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1100px){
      margin: 40px 27px 0 0;
      margin-right: 0;
      row-gap: 10px;
      width: 47%;
    }

    @media (min-width: 1101px) and (max-width: 1399px) {
      margin: 40px 0 0 0;
      row-gap: 10px;
    }

    &__title {
      font-family: $rounded-mplus-font-family;
      font-style: normal;
      font-weight: 800;
      font-size: 44px;
      @media (max-width: 930px){
        width: 100%;
      }

      @media (max-width: 1100px){
        line-height: 37px;
        font-size: 34px;
        margin-bottom: 0;
        width: 89%;
      }

      @media (min-width: 1101px) and (max-width: 1399px) {
        padding-right: 10px;
        line-height: 40px;
        font-size: 41px;
        width: 89%;
      }

      color: $main-text-color;
    }

    &__text {
      margin-right: -2px;

      font-family: $futura-pt-font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 28px;

      color: $main-text-color;

      opacity: 0.7;

      @media (max-width: 930px){
        width: 100%;
      }

      @media (max-width: 1100px){
        line-height: 22px;
        font-size: 16px;
        width: 89%;
      }

      @media (min-width: 1101px) and (max-width: 1399px) {
        width: 77%;
        line-height: 24px;
        font-size: 18px;
      }
    }
  }

  &__right {
    @media (max-width: 1100px){
      max-width: 507px;
      width: 53%;
      display: flex;
      justify-content: center;
      align-items: center;
    }


    @media (min-width: 1101px) and (max-width: 1399px) {
      // margin-right: -60px;
      max-width: 557px;
    }

    &__image {
      position: relative;
      min-width: 670px;

      @media (max-width: 1100px){
        margin-left: 10%;
      //   min-height: 312.47px;
      //   svg {
      //     height: 310px;
      //     width: 674px;
      //   }
      }

      @media  (max-width: 1399px) {
        min-height: 312.47px;
        svg {
          height: 310px;
          width: 674px;
        }
      }

      display: flex;
      align-items: center;
      justify-content: flex-end;

      .rec-carousel-wrapper {
        width: 470px;
        display: block;
        position: absolute;
        height: 500px;
        right: 86px;
        bottom: -69px;



        @media (max-width: 1399px) {
          width: 470px;
          height: 360px;
          right: 156px;
          svg {
            width: 354px;
            height: 310px;
          }
        }

        .rec-item-wrapper {
          width: 468px !important;
          padding: 0 3px !important;
        }

        .rec-pagination {
          background: transparent;
          display: flex;
          justify-content: center;
          padding: 15px 0;
          margin: 0;
          align-items: center;
          height: 45px;
          position: relative;
          z-index: 10;

          .rec-dot {
            -webkit-transition: all 250ms ease;
            transition: all 250ms ease;
            box-shadow: none;

            margin: 0 10px;
            padding: 0;
            width: 8px;
            height: 8px;
            background: $dash-color;

            outline: none;
            border-radius: 50%;

            &_active {
              width: 12px;
              height: 12px;
              background: #69C9A6;
            }
          }

          .rec-dot:hover, .rec-dot:focus {
            box-shadow: none;
          }
        }

        .rec-slider-container {
          margin: 0;
        }
      }

      > img {
        position: absolute;
        right: 69px;
        top: 25px;

        @media (max-width: 1399px) {
          top: 10px;
          height: 330px;
          left: 40px;
        }
      }
    }
  }
}

.container.cover-container {
  @media (max-width: 1200px) {
    max-width: 1140px;
    width: 100%;
  }

  @media (max-width: 815px) {
    height: 135px;
  }
  @media (max-width: 576px) {
    position: relative;
    top: -88%;
    // height: 135px;
    height: 82px;
  }
}

.cover-container, .main-page{
  .see-the-book {
    @media (min-width: 816px) and (max-width: 1200px) {
      display: grid;
      grid-template-columns: minmax(37%,400px) minmax(31%, 340px) auto;
      column-gap: 3%;
      padding: 0 32px;
    }
  }
}

.see-the-book {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 150px;
  height: 132px;
  width: 1180px;
  margin: 0 auto;





  @media (max-width: 1399px) {
    width: 100%;
  }

  label {
    position: relative;
  }

  label:before {
    content: "";
    position: absolute;
    left: 30px;
    top: 0;
    bottom: 0;
    width: 30px;
    background: url("../images/input-kids-name.svg") center / contain no-repeat;
  }

  &__input-name {
    padding-left: 75px;
    border: 2px solid $dash-color;
    box-sizing: border-box;
    border-radius: 35px;
    height: 60px;
    width: 400px;
    color: black;
    font-family: $futura-pt-font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    outline: none;

    @media (min-width: 816px) and  (max-width: 1200px) {
      width: 100%;
    }
  }

  &__toggler {
    height: 60px;
    margin: 0 47px;
    position: relative;

    @media (min-width: 816px) and  (max-width: 1200px) {
      max-width: 340px;
     width: 100%;
     margin: 0;
    }

    span {
      display: flex;
      position: absolute;
      pointer-events: none;
      align-items: center;
      z-index: 1;
      top: 17px;

      font-family: $futura-pt-font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #363636b3;

      @media (min-width: 816px) and  (max-width: 1200px) {
        padding-left: 10%;
        padding-top: 1px;
      }
    }

    &__boy {
      left: 25px;

      @media (min-width: 816px) and  (max-width: 1200px) {
        left: 7.5%;
      }

      .boy-sign {
        background: url("../images/boy-sign.svg") no-repeat 0 0;
        width: 34px;
        height: 28px;

        @media (min-width: 816px) and  (max-width: 1200px) {
          background-size: contain;
          max-width: 34px;
          width: 45%;
          position: absolute;
          left: -5%;
        }
      }
    }

    &__girl {
      right: 41px;

      @media (min-width: 816px) and  (max-width: 1200px) {
        right: 12%;
      }

      .girl-sign {
        background: url("../images/girl-sign.svg") no-repeat 0 0;
        width: 34px;

        height: 28px;


        @media (min-width: 816px) and  (max-width: 1200px) {
          background-size: contain;
          max-width: 34px;
          width: 45%;
          position: absolute;
          left: 0%;
        }
      }
    }

    .toggle-button {
      position: relative;

      width: 340px;
      height: 60px;
      margin: 0;

      vertical-align: top;

      background: #ffffff;
      border: 2px solid #EBEBEB;
      border-radius: 30px;
      outline: none;
      cursor: pointer;

      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);

      @media (min-width: 816px) and (max-width: 1200px) {
        width: 100%;
      }
    }

    .toggle-button::after {
      content: "";

      position: absolute;

      border: 2px solid #48C8F0;
      box-sizing: border-box;
      border-radius: 35px;
      width: 179px;
      height: 59px;
      left: -2px;
      top: -2px;

      transform: translateX(0);

      transition: all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);

      @media (min-width: 816px) and (max-width: 1200px) {
        max-width: 179px;
        width: 53%;
      }
    }

    .toggle-button:checked::after {
      transform: translateX(calc(89% + 2px));
      background-color: #fff;
      border: 2px solid #FE8496;
    }

    > button {
      height: 60px;
    }
  }

  &__look-book {
    height: 60px;
    white-space: nowrap;

    @media (max-width: 991px) {
      padding: 20px 23px;
    }
  }

  @media (max-width: 815px) {
    flex-direction: column;
    box-shadow: none;
    label > input, div > input, button, div {
      width: 305px;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 17px;
    }
  }

  @media (max-width: 815px) {
    flex-direction: column;
    box-shadow: none;
    width: auto;
    height: auto;

    label > input, div > input, button, div {
      width: 305px;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 25px;
    }

    .see-the-book__toggler .toggle-button {
      width: 305px;
    }

    .see-the-book__toggler .toggle-button:after {
      width: 156px;
      bottom: -2px;
      height: 60px;
    }

    label:before {
      left: 30px;
      bottom: 25px;
    }

    .toggle-button:checked::after {
      transform: translateX(calc(94% + 2px));
    }
  }
}

.see-the-book.cover {
  position: relative;
  bottom: 131px;

  @media (max-width: 870px) {
    bottom: 726px;
  }
  @media (min-width: 568px) and (max-width: 815px) {
    bottom: 803px;
  }

  @media (max-width: 737px) {
    bottom: 826px;
  }
  // @media (max-width: 567px) {
  //   bottom: 755px;
  // }
}

.header-block-image {
  background: url("../images/how-to-header.svg") no-repeat center center;
  width: 189px;
  height: 71px;

  @media (min-width: 320px) and (max-width: 815px) {
    background-size: 146px;
  }
}

.header-block-title {
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  color: $main-text-color;
  margin-top: 37px;
  width: 548px;

  @media (min-width: 320px) and (max-width: 815px) {
    font-size: 24px;
    line-height: 28px;
    width: 350px;
  }

  @media (min-width: 816px) and  (max-width: 1200px) {
    line-height: 37px;
    font-size: 34px;
  }
}

.header-block-title.constructor {
  display: none;
  @media (max-width: 825px) {
    font-family: $futura-pt-font-family;
    display: block;
    font-size: 18px;
    margin: 0 auto;
    width: 100%;
  }
}

.how-to-create {
  background: url("../images/how-background.svg") no-repeat center 0;
  height: 1015px;
  position: relative;
  margin-top: -100px;
  z-index: -1;


  @media(max-width:991px){
    background-size: 190%;
    max-height: 996px;
  }

  @media(max-width:900px){
    background-size: 199%;
  }


  @media (min-width: 816px) and  (max-width: 1200px) {

    height: max-content;
    overflow: hidden;

  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 180px;

    @media (max-width: 815px) {
      padding-top: 49px;
    }

    &__animals {
      background: url("../images/how-to-animals.svg") no-repeat 0 0;
      width: 1920px;
      height: 600px;
      margin-top: 80px;

      @media (min-width: 320px) and (max-width: 815px) {
        display: none;
      }

      @media (min-width: 816px) and  (max-width: 1200px) {
        background-size: contain;
        max-width: 1920px;
        width: 158%;
        margin-top: 41px;
      }
    }


  }

  &__steps {
    position: relative;
    justify-content: space-between;
    display: flex;
    padding: 0 73px;

    margin-top: 46px;
    top: -300px;


    @media(max-width:900px){
      top: -333px;
    }

    @media(max-width:991px){
      max-width: 95%;
      margin: 0 auto;
    }

    @media (min-width: 816px) and  (max-width: 1200px) {
      padding: 0;
    }
    @media(max-width:815px){
      margin-top: 46px;
    }
  }

  &__step {
    font-family: $futura-pt-font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: $main-text-color;
    width: 255px;
    cursor: text;

    i {
      display: none;
    }
  }

  @media  (max-width: 815px) {
    background: url("../images/how-background-mobile.svg") no-repeat center 0;
    margin-top: 30px;
    height: 753px;
    background-size: cover;
    margin-bottom: 10px;

    &__steps {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      padding: 0 20px;
      grid-row-gap: 15px;
      position: initial;
    }

    &__step {
      width: auto !important;
      height: 185px;
      margin: 0 !important;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      i {
        display: block;
      }

      span {
        height: 30%;
        font-size: 16px;
        line-height: 20px;
      }

      &__pic-1 {
        background: url("../images/how-to-grid-1.svg") no-repeat 0 0;
        width: 95px;
        height: 95px;
      }

      &__pic-2 {
        background: url("../images/how-to-grid-2.svg") no-repeat 0 0;
        width: 95px;
        height: 95px;
      }

      &__pic-3 {
        background: url("../images/how-to-grid-3.svg") no-repeat 0 0;
        width: 105px;
        height: 101px;
      }

      &__pic-4 {
        background: url("../images/how-to-grid-4.svg") no-repeat 0 0;
        width: 134.51px;
        height: 132.68px;

        background-size: 130px;
        margin-top: -14px;
      }

      &:nth-child(1) span {
        width: 155px;
      }

      &:nth-child(3) span {
        width: 148px;
      }

      &:nth-child(4) span {
        width: 137px;
      }
    }
  }

  @media (max-width: 350px) {

    &__steps {
      padding: 0 10px;
    }
  }

  &__step:nth-child(2) {
    width: 244px;
    margin-right: 20px;
  }

  &__step:nth-child(3) {
    width: 201px;
    margin-right: 48px;
  }

  &__step:nth-child(4) {
    width: 194px;
    margin-right: 27px;
  }
}

.cloud-right {
  background: url("../images/cloud-right.svg") no-repeat 0 0;
  width: 339px;
  height: 203px;

  position: absolute;
  right: -172px;
  top: 50px;
  z-index: -1;

  @media (min-width: 320px) and (max-width: 815px) {
    display: none;
  }
}

.cloud-left {
  background: url("../images/cloud-left.svg") no-repeat 0 0;
  width: 310px;
  height: 138.31px;

  position: absolute;
  top: 200px;
  left: -220px;

  @media (min-width: 320px) and (max-width: 815px) {
    position: absolute;
    z-index: -1;
    top: 200px;
    left: -42px;
    background-size: 80%;
  }
}

@media (min-width: 320px) and (max-width: 815px) {
  .cloud-right-mobile {
    background: url("../images/cloud-left.svg") no-repeat 0 0;
    width: 310px;
    height: 138.31px;

    position: absolute;
    z-index: -1;
    right: -135px;
    bottom: 225px;
    background-size: 80%;
  }
}

.interactive-book {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__pros {
    height: 471px;
    max-width: 1074px;
    margin: 62px 0 58px;

    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 355px) {
      width: 100%;
    }

    .background-image-block {
      position: absolute;
      top: 30%;
      left: 33%;

      img {
        position: absolute;
        z-index: 0;
        top: -23px;
        left: 18px;
      }

      svg {
        position: absolute;
        z-index: 1;
      }

      @media (max-width: 1200px) {
        display: none;
      }
    }

    @media (min-width: 320px) and (max-width: 815px) {
      background: none;
      height: auto;
    }

    &__clouds {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-column-gap: 260px;
      grid-row-gap: 70px;
      z-index: 2;

      position: relative;
      right: 50px;
      width: 100%;

      // @media (min-width: 816px) and (max-width: 1500px) {
      //   right: 0;
      // }

      @media (max-width: 1200px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-row-gap: 30px;
        position: initial;
      }

      @media (max-width: 815px) {
        grid-row-gap: 0;
      }

      @media (max-width: 355px) {
        max-width: 300px;
        width: 100%;
        grid-template-columns: 100%;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: center;

        font-family: $futura-pt-font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        color: #FFFFFF;

        position: relative;

        @media (min-width: 320px) and (max-width: 815px) {
          background-size: 74%;

          span {
            font-size: 18px;
            line-height: 30px;
          }
        }

        @media (max-width: 355px) {
          background-size: 100%;
          width: 100%;
        }
      }

      &-1 {
        background: url("../images/interactive-book-text-cloud-1.svg") no-repeat center center;
        width: 461px;
        height: 96px;

        @media (max-width: 1200px) {
          .m-icon {
            background: url("../images/m-icon.svg") no-repeat;
            width: 50px;
            height: 50px;

            position: absolute;
            right: 125px;
            top: -15px;
          }
        }
      }

      &-2 {
        background: url("../images/interactive-book-text-cloud-2.svg") no-repeat center center;
        width: 461px;
        height: 96px;

        @media  (max-width: 1200px) {
          .sun-icon {
            background: url("../images/sun-icon.svg") no-repeat;
            width: 75px;
            height: 75px;

            position: absolute;
            right: 33px;
            top: -20px;
          }
        }
      }

      &-3 {
        background: url("../images/interactive-book-text-cloud-3.svg") no-repeat center center;
        width: 461px;
        height: 96px;

        @media (max-width: 1200px) {
          .whale-icon {
            background: url("../images/whale-icon.svg") no-repeat;
            width: 83px;
            height: 60px;

            position: absolute;
            left: 55px;
            top: -35px;
          }
        }
      }

      &-4 {
        background: url("../images/interactive-book-text-cloud-4.svg") no-repeat center center;
        width: 461px;
        height: 96px;

        @media (max-width: 1200px) {
          .book-icon {
            background: url("../images/book-icon.svg") no-repeat;
            width: 61px;
            height: 69px;

            position: absolute;
            left: 64px;
            top: -6px;
          }

          .e-icon {
            background: url("../images/e-icon.svg") no-repeat;
            width: 50px;
            height: 50px;

            position: absolute;
            right: 49px;
            top: 1px;
          }

          .o-icon {
            background: url("../images/o-icon.svg") no-repeat;
            width: 50px;
            height: 50px;

            position: absolute;
            right: 91px;
            top: 58px;
          }
        }

        @media (max-width: 355px) {
          .book-icon {
            left: 0;
          }
        }
      }
    }
  }


  &__create {
    margin-bottom: 100px;

    @media (min-width: 320px) and (max-width: 815px) {
      height: 66px;
      width: 232px;
      margin-bottom: 58px;
    }

  }

  &__create:hover {
    box-shadow: 0px 10px 20px #48c8f0;
  }
}

.cover-section {
  width: 100%;

  &__wrapper {
    background: url("../images/cover-section-background.svg") no-repeat center 0;
    background-position-x: 57%;
    height: 1230px;
    max-width: 1921px;
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    z-index: -1;

    @media (max-width: 870px) {
      background: url("../images/cover-section-bg-mobile.svg") no-repeat center 0;
      padding-top: 118px;
      background-position-x: 59%;
      height: auto;
      margin-bottom: -103px;
    }

    @media (max-width: 576px) {
      margin-bottom: -58px;
      margin-bottom: -3px;
      background-position-x: 55%;
      padding: 96px 10px 10px 10px;
    }
  }

  .cover-trees {
    background: url("../images/cover-trees.svg") no-repeat;
    height: 353px;
    width: 1563px;
    position: relative;
    bottom: 228px;
    left: 22px;
    background-size: contain;

    @media (max-width: 870px) {
      display: none;
    }
  }

  .header-block-title.cover {
    margin-top: -110px;
    @media (max-width: 870px) {
      display: none;
    }
  }

  .header-block-title.cover-mobile {
    display: none;

    @media (min-width: 767px) and (max-width: 870px) {
      display: block;
      font-size: 24px;
      line-height: 28px;
      margin-top: 16px;
    }

    @media (min-width: 767px) and (max-width: 815px){
      margin-top: -7px;
    }

    @media (max-width: 767px) {
      display: block;
      font-size: 24px;
      line-height: 28px;
      margin-top: 18px;
    }

  }

  .types-book {
    display: flex;
    justify-content: space-around;
    margin-top: 66px;
    margin-bottom: 93px;

    @media (max-width: 870px) {
      flex-direction: column;
      width: 100%;
      margin-top: 18px;
      justify-content: center;
      align-items: center;
    }

    &__item {
      width: 50%;

      &:first-child {
        margin-right: 33px;
      }

      @media (max-width: 870px) {
        margin-top: 20px;
        width: 81%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:first-child {
          margin: 10px 0 0;
        }
      }
    }
  }

  .types-book__item{
    @media (max-width: 350px) {
      width: 100%;
    }
  }

  .types-book__item__cover-img {
    width: 392px;
    height: auto;
    filter: drop-shadow(0px 25px 30px rgba(0, 0, 0, 0.15));
    border-radius: 10px;

    @media (max-width: 870px) {
      width: 284px;
      height: 188px;
    }
  }

  .types-book__item__cover-img.soft {
    background: url("../images/soft-cover.svg") no-repeat;

    @media (max-width: 870px) {
      background: url("../images/soft-cover-mobile.svg") no-repeat;
      margin: 0 auto;
    }
  }

  .cover-section__or {
    display: none;

    @media (max-width: 870px) {
      display: block;
      background: url("../images/cover-section__or.svg") no-repeat;
      width: 98px;
      height: 32px;

      margin-top: 12px;
      margin-bottom: 6px;

    }
  }

  .types-book__item__cover-img.hard {
    background: url("../images/hard-cover.svg") no-repeat;

    @media (max-width: 870px) {
      background: url("../images/hard-cover-mobile.svg") no-repeat;
      margin: 0 auto;
    }
  }

  .types-book__item__info {
    display: flex;
    flex-direction: column;
    width: 227px;
    margin: 20px auto 0;

    @media (max-width: 870px) {
      flex-direction: row;
      justify-content: space-between;
      width: 283px;
      margin: 0 auto;
      margin-top: 12px;
    }


    &__price {
      @include header-title;

      @media (max-width: 870px) {
        font-size: 24px;
        text-align: right;
      }
    }

    &__cover-info {
      margin-left: -26px;

      font-family: $futura-pt-font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 46px;
      text-align: center;
      color: #706E6B;
      @media (max-width: 870px) {
        margin-left: 0;

      }
    }
  }

  .cover-section__header-info {
    display: none;

    @media (min-width: 816px) and (max-width: 870px) {
      margin-top: 110px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @media (max-width: 815px) {

      margin-top: 250px;
      display: flex;
      flex-direction: column;
      align-items: center;

    }

    @media (min-width: 600px) and (max-width: 737px) {
      margin-top: 238px;
    }
    &__img {
      width: 340px;
      height: 310px;
      margin: 25px 5px;
      border-radius: 10px;
    }
  }

  .cover-section__info {
    display: flex;
    justify-content: space-around;
    width: 38%;

    @media (max-width: 870px) {
      width: 75%;
      justify-content: center;
      margin-top: 26px;
    }

    @media (max-width: 576px) {
      width: 100%;
      margin-top: 10px;
    }

    &__item:first-child {
      margin-right: 120px;
      @media (max-width: 870px) {
        margin-right: 28px;
      }
      @media (max-width: 361px) {
        margin-right: 5px;

      }
    }

    &__item {
      display: flex;

      .info {
        width: 176px;

        font-family: Futura PT;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        color: #363636;

        margin-left: 48px;
        @media (max-width: 870px) {
          width: 99px;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          margin-left: 10px;
        }
        @media (max-width: 576px) {
          width: 99px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          margin-left: 10px;
        }
      }
    }
  }

  .hedgehog {
    background: url("../images/hedgehog.svg") no-repeat;
    width: 151px;
    height: 139px;

    position: relative;
    bottom: 37px;

    @media (max-width: 870px) {
      transform: scale(0.65);
    }

    @media (max-width: 576px) {
      background: url("../images/hedgehog-mobile.svg") no-repeat;
      width: 60px;
      height: 40px;
      bottom: -6px;
    }
  }

  .bird {
    background: url("../images/bird.svg") no-repeat;
    width: 102px;
    height: 101px;

    position: relative;
    bottom: 13px;

    @media (max-width: 870px) {
      transform: scale(0.65);
    }

    @media (max-width: 576px) {
      background: url("../images/bird-mobile.svg") no-repeat;
      width: 49px;
      height: 41px;

      bottom: -2px;
    }
  }

}

.reviews {
  height: 1026px;
  width: 100%;

  &__container {
    max-width: 1920px;
    height: 100%;
    margin: -51px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
  }

  &__container > .rec.rec-carousel-wrapper {
    position: absolute;
    top: 52%;
    right: 13%;
    left: auto;

    display: block;
    background-image: url("../images/reviewss-slider-bg.svg");
    width: 548px;
    height: 410px;



    .galrjd {
      width: 93%;
      margin: 0 auto;
    }

    .rec-slider-container {
      margin: 0;
    }

    .reviews__slider-wrapper {
      position: relative;
      width: 548px;
      height: 410px;

      @media (min-width: 450px) and (max-width: 767px) {
        width: 275px;
      }
    }

    .review-author {
      position: absolute;
      top: 15%;
      left: 10%;

      font-family: $rounded-mplus-font-family;
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 30px;

      color: #FE8496;



      @media (min-width: 450px) and (max-width: 815px) {
        left: 0;
        top: 15%;
      }

      @media (max-width: 1200px) {
        top: 10%;
        left: 5%;
        font-size: 20px;
        line-height: 24px;
      }
    }

    .review-text {
      position: absolute;
      width: 434px;
      height: 195px;
      overflow: hidden;
      top: 28%;
      left: 10%;

      font-family: $futura-pt-font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: $main-text-color;

      @media (min-width: 450px) and (max-width: 815px) {
        left: 0;
        top: 24%;
        width: auto;
        height: 215px;
      }

      @media (max-width: 1200px) {
        width: auto;
        height: auto;
        font-size: 15px;
        line-height: 21px;
        top: 19%;
        left: 5%;
      }
    }


    .reviews__pagination {
      position: absolute;
      bottom: 13%;
      left: 45%;

      font-family: $rounded-mplus-font-family;
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 30px;

      letter-spacing: 0.1em;

      &__active {
        color: #FE8496;
      }
    }

    .reviews-slider-btn {
      width: 45px;
      height: 45px;
      background: #69C9A6;
      border-radius: 35px;
      transform: rotate(180deg);
      position: absolute;
      bottom: 11%;
      z-index: 2;

      &:after {
        content: url("../images/book-step-arrow-left.svg");
        position: relative;
        display: inline-block;
        top: 22%;
      }

      &:active{
        box-shadow: inset 0px -1px 10px rgba(0, 0, 0, 0.25);
      }
    }

    .reviews-slider-btn.prev {
      left: 34%;
      cursor: pointer;

      @media (max-width: 1200px){
        left: 30%;
      }

      &:after {
        left: 36%;

        transform: rotate(180deg);
      }
    }

    .reviews-slider-btn.next {
      left: 55%;
      cursor: pointer;

      @media (max-width: 1200px) {
        left: 60%;
      }

      &:after {
        top: 29%;
        left: 31%;
      }
    }

  }

  &__title {
    @include header-title;
    margin-top: 37px;
    width: 548px;
    margin-bottom: 97px;

    @media (max-width: 1200px) {
      line-height: 37px;
      font-size: 34px;
    }
  }

  &__main-info {
    overflow: hidden;

    &__photos__top, &__photos__bottom {
      display: grid;
      grid-template-columns: repeat(6, max-content);
      margin-bottom: 17px;
      grid-gap: 17px;
    }

    &__photos__bottom {
      grid-template-columns: repeat(7, max-content);
    }
  }

}

.contacts-section {
  width: 100%;
  height: 957px;

  &__container {
    max-width: 1920px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 75px;
    background-size: contain;

    &__frame {
      background: url("../images/contacts-background.svg") no-repeat center 0;
      width: 1207px;
      height: 781px;
      padding: 84px 120px 120px 120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-size: contain;
    }

    .contacts-section__info {
      margin-bottom: 35px;
      padding-top: 35px;
      font-family: $rounded-mplus-font-family;
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 34px;
      text-align: center;
      width: 81%;
    }

    .contacts-section__map {
      display: flex;


      img {
        margin-right: 29px;
      }

      &__info {
        font-family: $futura-pt-font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
      }
    }

    .contacts-section__phones {
      margin-top: 35px;
      width: 340px;


      @media (max-width: 576px) {
        width: 68%;
      }
    }

    .contacts-section__phone:nth-child(2) {
      margin-bottom: 0;
    }

    .contacts-section__phone {
      display: flex;
      // margin-bottom: 45px;

      img {
        margin-right: 26px;
        margin-bottom: 45px;
      }

      &__number a {
        text-decoration: none;
        font-family: $montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 20px;
        text-align: center;
        color: $main-text-color;
        margin-bottom: 12px;
      }

      &__number div {
        font-family: $futura-pt-font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        opacity: 0.7;
        margin-top: 5px;
      }
    }

    &__slider {
      position: absolute;
    }
  }
}


.social-network {
  background: url("../images/social-background.svg") no-repeat center 0;
  height: 670px;
  margin-top: -102px;
  width: 100%;
  overflow: hidden;

  &__wrapper{
    position: relative;
    height: 100%;
    max-width: 1920px;
    margin: 0 auto;
  }

  &__letter-A {
    position: absolute;
    top: 14%;
    left: 14%;
    z-index: -1;
  }

  &__letter-P {
    position: absolute;
    top: 65%;
    left: 42%;
  }

  &__letter-M {
    position: absolute;
    top: 22%;
    right: 16%;
  }

  &__letter-B {
    position: absolute;
    right: 8%;
  }

  &__container {
    max-width: 1920px;
    margin: 0 auto;
    padding-top: 162px;
    padding-left: 26em;
    padding-right: 18em;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 1980px;

    @media (max-width: 1750px) {
      padding-left: 21em;
    }
  }

  @media (max-width: 2100px) {

    &__letter-A {
      left: 11%;
    }

  }

  @media (max-width: 1550px) {
    &__container {
      width: 1750px;
      padding-left: 14em;
    }

    &__letter-A {
      left: 5%;
    }
  }

  @media (min-width: 768px) and (max-width: 1300px) {
    &__container {
      width: 1638px;
      padding-left: 8em;
    }

    &__letter-A {
      left: 2%;
    }

    &__letter-M {
      right: 26%;
    }

    &__letter-B {
      right: 20%;
    }
  }

  &__title {
    font-family: $rounded-mplus-font-family;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 46px;
    width: 469px;
    color: $main-text-color;

  }

  &__links {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 45px 0;

    div {
      font-size: 20px;
      cursor: pointer;

      @media (min-width: 816px) {
        font-size: 40px;
      }
    }
  }


  .social_icon:hover {
    filter: drop-shadow(0px 10px 20px #5489BA);
    border-radius: 50%;
    background: #5489ba;
  }

  &__rules {
    font-family: $futura-pt-font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #F4757A;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.9;

    &:hover {
      color: #F4757A;
      opacity: 0.7;
    }

    &:active {
      opacity: 1;
    }
  }

  &__rules:after {
    content: url("../images/rules-after.svg");
    position: relative;
    top: 17px;
    right: 182px;
  }

  .social-elephant {
    position: absolute;
    right: 15%;
    bottom: 21px;
    z-index: 2;

    @media (max-width: 1750px) {
      right: 6%;
    }

    @media (max-width: 1200px) {
      right: -45px;
    }
  }
}

footer {
  width: 100%;
  height: 262px;
  background: url("../images/footer-background.svg") no-repeat center 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 190PX;
  overflow: hidden;
  position: relative;
  z-index: 3;

  .footer__container {
    width: 1920px;
    display: flex;
    justify-content: space-around;
    margin-top: -80px;

    @media (max-width: 1200px){
      max-width: 1164px;
      padding: 0 20px;
      width: 100%;
      justify-content: space-between;
    }
  }

  .contacts {
    width: 32%;
    display: flex;
    justify-content: space-around;
    margin-right: 5%;

    @media (max-width: 1200px){
      width: 38%;
    }

    &__phone {
      text-decoration: none;
      font-family: $futura-pt-font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #94E5B7;
    }


    &__email {
      text-decoration: none;
      font-family: $futura-pt-font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #FFD850;
    }
  }

  .copyright {
    width: 192px;
    height: 54px;
    font-family: $futura-pt-font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #FFFFFF;
    position: relative;
    right: 100px;
  }
}

footer.landing {
  margin-top: -102px;
  @media (max-width: 812px) {
    position: relative;
    z-index: 3;
  }
}

.page.--left .page-content img {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.page.--right .page-content img {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

@media (min-width: 1250px) and (max-width: 1500px) {
  // .reviews {
  //   &__container > .rec.rec-carousel-wrapper {
  //     left: 25%;
  //   }
  // }

  .contacts-section {
    &__container__frame {
      width: 98%;
      padding: 87px 50px;
    }
  }
}

@media (min-width: 1000px) and (max-width: 1249px) {
  // .reviews {
  //   &__container > .rec.rec-carousel-wrapper {
  //     left: 25%;
  //   }
  // }

  .contacts-section {
    &__container__frame {
      width: 98%;
      padding: 73px 50px;
    }
  }
  .social-network {
    &__container {
      padding-left: 12em;
    }
  }
}

.reviews__main-info__photos__top,
.reviews__main-info__photos__bottom {
  img {
    height: 375px;
    border-radius: 10px;
  }

  @media (max-width: 815px) {
    grid-gap: 5px;
  }
}

@media (max-width: 1150px) {
  .contacts-section {
    height: fit-content;

    &__container {
      padding: 20px 0;

      &__frame {
        background-image: none;
        padding: 16px;
        height: 651px;

        .how-to-create__header__image {
          height: 95px;
        }

        .contacts-section__info {
          padding-top: 10px;
        }
      }
    }
  }
}


@media (max-width: 1100px) {
  .social-network {
    height: 780px;

    &__wrapper {
      background-color: #fff7db;
      padding-bottom: 43px;
      height: 100%;
    }

    &__container {
      flex-direction: column;
      height: 100%;
      padding-left: 9em;
      padding-right: 4em;
    }

    .social-elephant {
      width: 50%;
    }

    &__letter-A {
      top: 7%;
      left: 6%;
    }

    &__letter-P {
      top: 78%;
      left: 28%;
    }
  }

  footer {
    .footer__container {
      justify-content: space-between;
    }

    .contacts {
      margin-right: 0;
      width: 47%;
    }

    .copyright {
      right: 5%;
    }
  }
}

@media (min-width: 450px) and (max-width: 1200px) {
  .reviews {
    height: 718px;

    &__title {
      margin-bottom: 46px;
    }

    &__main-info__photos {
      position: absolute;
      left: -10px;
    }

    &__main-info__photos__top img, &__main-info__photos__bottom img {
      height: 236px;
    }

    &__container > .rec.rec-carousel-wrapper {
      // top: 58%;
      width: 407px;
      height: 312px;
      background-size: contain;
      background-repeat: no-repeat;
      top: 52%;
    }

    .reviews__pagination {
      bottom: 15%;
    }
  }
}

@media (max-width: 767px) {
  .reviews {
    &__container > .rec.rec-carousel-wrapper {
      background-image: url("../images/review-mobile.png");
      background-repeat: no-repeat;
      overflow: hidden;
      background-position: center top;
      top: 51%;
      height: 353px;
      width: 100%;
      max-width: 100%;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  .social-network{
    &__wrapper{
      padding-bottom: 306px;

      .social-network__container{
        width: 86%;

        .social-network__title{
          width: 100%;
          text-align: center;
        }
      }
    }

    .social-elephant{
      bottom: -17px;
    }
  }
}
@media (max-width: 449px) {

  .reviews {

    height: 1000px;

    &__main-info__photos__top img, &__main-info__photos__bottom img {
      height: 192px;
    }

    &__container > .rec.rec-carousel-wrapper {

      img {
        transform: scale(0.8, 1);
      }

      .rec.review-author, .rec.review-text {
        left: 18%;
      }

      .rec.review-text {
        width: 326px;
      }

      .reviews__pagination {
        margin-bottom: -70px;
      }

      .reviews-slider-btn {
        bottom: -10%;
      }

      .reviews-slider-btn.next {
        left: 57%;
      }

      .reviews-slider-btn.prev {
        left: 30%;
      }

    }


  }

  .contacts-section {
    &__container {
      margin-bottom: 55px;
      padding: 0;

      .contacts-section__info {
        width: 19em;
      }

      .contacts-section__map__info {
        width: 14em;
        font-size: 21px;
      }
    }
  }

  .social-network {
    margin-top: -90px;

    &__wrapper {
      background-color: #fff7db;
      padding-bottom: 43px;
      height: 100%;
    }

    &__container {
      padding-top: 140px;
    }

    &__contacts {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__title {
      font-size: 25px;
      line-height: 32px;
      width: 452px;
      text-align: center;
    }

    &__links {
      margin: 41px 0;
    }

    &__rules {
      width: 184px;

      &::after {
        top: -8px;
        right: 0px;
      }
    }

    .social-elephant {
      width: 350px;
      transform: translate(53px, -44px);
    }

    &__letter-P {
      top: 0;
      left: 82%;
    }

    &__letter-B {
      top: 51%;
      right: 2%;
      z-index: -1;
    }

    &__letter-M {
      top: 58%;
      left: 3%;

      @media (max-width: 378px) {
        left: -18%;
      }
    }
  }

  footer {
    padding-top: 135px;

    .footer__container {
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      padding-bottom: 9px;
      height: 100%;
    }

    .contacts {
      flex-direction: column;
      margin-right: 0;
      width: 153px;
      margin-bottom: 20px;
      margin-left: 0;

      &__phone {
        margin-bottom: 10px;
      }
    }

    .copyright {
      text-align: center;
      left: 0;
      margin-bottom: 10px;
    }
  }
}


@media (max-width:576px){
  .contacts-section__container{
    .contacts-section__map{
      width: 100%;
      max-width: 336px;
    }

    .contacts-section__info{
      font-size: 18px;
      line-height: 28px;
    }
    .contacts-section__map__info{
      font-size: 18px;
      line-height: 24px;
      max-width: 247px;
    }

    .contacts-section__phones{
      width: 100%;
      max-width: 328px;

      .contacts-section__phone__number{

        div{
          text-align: start;
          line-height: 24px;
        }
      }
    }
  }
}

@media (max-width: 815px) {
  .reviews {
    .reviews__title {
      line-height: 37px;
      font-size: 34px;
    }

  }
  .social-network {
    height: auto;
    padding-top: 88px;

    &__title{
      font-size: 24px;
      line-height: 36px;
    }

    &__wrapper {
      background-color: $background-active-color;
      padding-bottom: 331px;
    }

    &__container {
      width: 328px;
      padding: 0;
      flex-direction: column;
      align-items: center;
      padding-top: 53px;
    }

    &__contacts {
      display: flex;
      flex-direction: column;
      align-items: center;
    }


    &__links {
      margin-left: 30px;
    }


    &__rules {
      width: 184px;
    }

    &__rules:after {
      position: relative;
      top: -13px;
      left: -2px;
    }

    .social-elephant {
      right: auto;
      left: 50%;
      width: 307px;
      transform: translate(-50%, -37px) rotate(0deg);
    }


    &__letter-A {
      width: 44px;
      height: 45px;
      top: -1%;
      left: 10%;
    }

    &__letter-P {
      width: 32px;
      height: 49px;
      top: -6%;
      left: 90%;
      transform: rotate(47deg);
    }

    &__letter-M {
      top: 64%;
      left: -14%;
    }

    &__letter-B {
      right: -12%;
      width: 50px;
      top: 51%;
    }
  }

  .footer__container{
    .contacts{
      width: 55%;
    }
  }
}
@media (max-width:767px){
  .reviews {
    .reviews__title {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 25px;
      margin-top: 13px;
    }
    &__main-info__photos__top img, &__main-info__photos__bottom img {
      height: 192px;
    }
  }

  .contacts-section__container{
    .contacts-section__map{
      width: 350px;

      img{
        margin-right: 20px;
      }

      &__info{
        max-width: 270px;
        text-align: start;
      }
    }
  }
  .social-network .social-elephant {
    right: auto;
    transform: translate(-37%, -69px) rotate(-5deg);

  }
  footer {
    background: url("../images/footer-bg-mobile.svg") no-repeat center 0;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 204PX;
    background-position-x: 52%;
    margin-top: -99px;
    height: 349px;
    z-index: 3;


    .footer__container {
      flex-direction: column;
      padding-bottom: 48px;
      background-color: #363636;
      width: 100%;
      align-items: center;
    }

    .contacts {
      flex-direction: column;
      align-items: center;
      width: 47%;
      margin-bottom: 38px;
      margin-right: 0;

      &__phone a {
        margin-bottom: 31px;
      }
    }

    .copyright {
      position: static;
      text-align: center;
      line-height: 20px;
      width: 200px;
    }
  }
}

@media (min-width: 768px) and (max-width: 815px) {
  footer.landing {
    margin-top: -140px;
  }

}

@media (max-width: 450px) {
  .reviews {
    height: 643px;


    &__main-info__photos__top img, &__main-info__photos__bottom img {
      height: 120px;
    }

    &__main-info__photos {
      position: absolute;
      left: -10px;
    }

    &__container > .rec.rec-carousel-wrapper {
      top: 45%;
      height: 353px;

      .sc-eCssSg.galrjd.rec.rec-carousel {
        height: 323px;
        width: 358px;
        margin: 0 auto;
        overflow: hidden;
      }

      img {
        transform: scale(0.65, 0.9);
      }

      .rec.review-author, .rec.review-text {
        left: 11%;
      }

      .rec.review-author {
        font-size: 18px;
        top: 8%;
      }

      .rec.review-text {
        width: 288px;
        height: 191px;
        top: 16%;
      }

      .reviews__pagination {

        bottom: 36%;
        left: 42%;
      }

      .reviews-slider-btn {
        bottom: 5%;
      }

      .reviews-slider-btn.next {
        left: 60%;
        bottom: 14%;
      }

      .reviews-slider-btn.prev {
        left: 24%;
        bottom: 14%;
      }
    }

  }
  .contacts-section {
    height: 685px;

    &__container {
      width: 90%;


      &__frame {
        background: none;
        background-color: white;
        padding: 0;
        z-index: -2;
        margin: 20px 18px 17px 18px;
      }

      .contacts-section__info {
        font-size: 18px;
        line-height: 28px;
        width:100%;
        // margin-left: 20px;
        margin-bottom: 43px;
      }

      .contacts-section__map {
        // width: 322px;

        &__info {
          font-size: 18px;
          line-height: 24px;
          text-align: start;
        }
      }

      .contacts-section__phones {
        // width: 322px;
        margin-top: 43px;
        max-width: 100%;
        // margin-left: 0.95em;

      }

      .contacts-section__phone {
        // margin-bottom: 43px;

        img {
          margin-right: 33px;
        }

        &__number a {
          font-size: 24px;
          line-height: 20px;
        }
      }
    }


  }
  .social-network {
    &__title {
      font-size: 20px;
      line-height: 28px;
      width: 100%;
      text-align: center;
    }
  }
}


@media (max-width: 378px) {

  .reviews {
    &__container > .rec.rec-carousel-wrapper {
      top: 49%;

      img {
        transform: scale(0.6, 0.9);
      }
    }

    &__main-info__photos__top img, &__main-info__photos__bottom img {
      height: 110px;
    }

    &__main-info__photos {
      position: absolute;
      left: -10px;
    }
  }
  .contacts-section {

    height: 655px;
    &__container__frame {
      margin: 20px 0px 17px 0px;
    }

    .contacts-section__phones {
      margin-left: 0;

      .contacts-section__phone__number div {
        font-size: 0.85em;
      }
    }
  }

  footer {
    .copyright {
      width: 198px;
      line-height: 23px;
    }
  }

}

@media (min-width: 1020px) and (max-width: 1200px) {
  .container.personalized-alphabet, .container.header {
    max-width: 97%;
  }
}

.contacts__phone > span {
  @media(max-width: 812px) {
    display: none;
  }
}

.contacts__phone > a {
  display: none;
  @media(max-width: 812px) {
    display: block;
  }
}

.btn-green.mob-menu {
  width: calc(100% - 30px);
  display: flex;
  justify-content: center;
}

.container.main-page{
  @media(max-width: 1200px) {
    max-width: 1140px;
    width: 100%;
  }
}

.sc-eCssSg.galrjd.rec.rec-carousel{
  @media(max-width: 815px) {
    // max-height: 400px;
  }
}

.container.cloud-block{
  @media (max-width: 1200px){
    max-width: 1164px;
    padding: 0 20px;
    width: 100%;
    margin: 0 auto;
  }

  @media (max-width: 355px) {
    padding: 0 8px;
  }
}

@media (max-width: 350px){

  .reviews{
    .reviews__container{
      .rec-carousel-wrapper{
        .rec.review-author, .rec.review-text{
          left: 20px;
        }
      }
    }

  }

}