@import "GenderStep";
@import "Book";
@import "WishStep";
@import "CoverStep";
@import "OrderStep";
@import "SuccessStep";

.gender_section {
  width: 100%;
  &__container {
    max-width: 1920px;
    background: url("../images/order-background.svg") no-repeat center 0;
    background-position-y: 131px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    //min-height: 640px;
    //row-gap: 70px;

    @media (max-width: 1250px) {
      //row-gap: 30px;
    }

    @media (max-width: 815px) {
      min-height: 525px;
      //row-gap: 20px;
    }

    @media (max-width: 375px) {
      padding: 0;
    }

    @media (min-width: 1101px) and (max-width: 1399px) {
      @media (min-height: 490px) and (max-height: 675px){
        min-height: 350px;
      }
      @media (min-height: 675px) and (max-height: 760px) {
        min-height: 430px;
      }
      @media (min-height: 760px) and (max-height: 845px){
        min-height: 500px;
      }
      @media (min-height: 845px) and (max-height: 900px){
        min-height: 570px;
      }
      @media (min-height: 900px) and (max-height: 960px){
        min-height: 620px;
      }

    }
  }

  .order-steps {
    margin-top: 31px;
    background: url("../images/order-step-wave.svg") no-repeat center;
    display: flex;
    margin-bottom: 29px;
    width: 669px;
    justify-content: space-between;
    padding: 2px 0;

    @media (max-width: 815px) {
      padding-left: 0;
      display: flex;
      justify-content: space-between;
      background-size: contain;
      width: 90%;
      margin-bottom: 26px;
      margin-top: 24px;
    }

    @media (min-width: 630px) and (max-width: 815px) {
      width: 68%;
    }

    @media (min-width: 1101px) and (max-width: 1399px) {
      margin-top: 15px;
      width: 500px;
      background-size: contain;
      background-position: center;
    }
  }

  .step {
    border-radius: 50%;
    background: #FFFFFF;
    border: 2px dashed #EBEBEB;
    width: 28px;
    height: 28px;
    position: relative;
    font-family: 'Coiny', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #DDDDDD;

    .number-step {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    span:nth-child(2) {
      display: inline;
    }

    @media (max-width: 815px) {
      padding: 8px;
      font-size: 21px;
      line-height: 19px;
      width: 35px;
      height: 35px;

      padding-left: 5px;
      padding-top: 11px;


      span:nth-child(1) {
        display: inline;
        font-size: 16px;
        line-height: 21px;
        font-style: normal;
        font-weight: bold;

      }

      span:nth-child(2) {
        display: none;
      }
    }

    @media (min-width: 1101px) and (max-width: 1399px) {
      padding: 0.5em;
      font-size: 17px;
      width: 22px;
      height: 22px;
      margin-right: -20px;
      margin-left: -20px;
    }

    &__text {
      left: -51px;
      position: absolute;
      width: 126px;
      top: 31px;
      font-family: $futura-pt-font-family;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 28px;
      text-align: center;

      color: #363636;

      @media (max-width: 815px) {
        display: none;
      }

      @media (min-width: 1101px) and (max-width: 1399px) {
        top: 25px;
        font-size: 13px;
      }
    }
  }


  .step:not(:last-child) {
    @media (max-width: 815px) {
      margin-right: auto;
    }
  }

  .step:last-child {
    @media (max-width: 815px) {
      margin-right: 0;
    }
  }

  .step.done {
    background: #69C9A6;
    border: 2px solid #69C9A6;
    padding: 0;
    cursor: pointer;

    .number-step {
      display: none;
    }

    @media (max-width: 815px) {
      .step__text {
        display: none;
      }
    }

  }

  .step.current {
    border: 2px solid #69C9A6;
    color: #69C9A6;
    cursor: pointer;
    @media (max-width: 815px) {
      width: 35px;
      height: 35px;
    }

  }

  .step.done:after {
    content: "";
    background: url("../images/checked.svg") center center no-repeat;
    width: 35px;
    height: 35px;
    background-size: 50%;
    margin-left: 1.5px;

    @media (max-width: 815px) {
      left: 1px;
    }
  }


  .btns-block {
    display: flex;
    justify-content: center;
    width: 100%;
    //align-items: flex-start;
    margin-bottom: 58px;
    z-index: 3;

    .btn-wrapper{
      width: 50%;
    }

    .btn-white{
      margin-left: auto;
      margin-right: 12px;
      height: 100%;
    }

    .btn-green {
      margin-left: 13px;
      height: 100%;
    }

    @media (max-width: 400px) {
      width: 100%;
      justify-content: space-around;

      .btn-green {
        //margin: 0;
      }

      button {
        width: 160px;
      }
    }

    //@media (min-width: 1101px) and (max-width: 1399px) {
    //  margin-bottom: 35px;
    //  .btn-green{
    //    height: 43px;
    //    width: 143px;
    //    font-size: 14px;
    //  }
    //
    //  .btn-white{
    //    height: 43px;
    //    width: 143px;
    //    font-size: 14px;
    //  }
    //
    //}
  }

  .btns-block.book-mobile {
    @media (min-width: 1251px) and (max-width: 1399px) {
      margin-bottom: 36px;
    }

    @media (max-width: 1250px) {
      right: 0;
      margin-top: 25px;
      margin-bottom: 67px;
      padding: 0 20px;
    }

    &.book-step {
      @media (max-width: 812px) {
        margin-bottom: 80px;
      }


      @media (max-width: 812px) {
        margin-bottom: 122px;
      }
    }
  }
  .btns-block.book-mobile.wish{
    @media (max-width: 812px) {
      margin-bottom: 133px;
    }
  }
}

@media (max-width: 815px) {
  .gender_section {
    width: 100%;
  }
}

.create_book__wrapper{
  min-height: calc(100vh - 160px);
  z-index: 10;
  position: relative;
  @media (max-width: 815px) {
    min-height: calc(100vh - 122px);
  }

}

.slideout-wrapper.open{
  position: relative;
  z-index: 10000;
}
