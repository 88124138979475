.modal {
  z-index: 10000;
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 250px;
  min-height: 100px;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  background: #fff;
  display: block;
  width: auto;
  height: auto;

  @media (max-width: 815px) {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }


  &__exit-button {
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
  }

  &__exit-button.mobile {
    display: none;

    @media (max-width: 815px) {
      display: block;
      z-index: 1000;
      top: 27px;
      width: 27px;
    }

    @media (max-width: 815px) {
      left: 7%;
    }
  }
}

.bg {
  z-index: 9998;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}

.are-you-sure-exit-modal {
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 54px 70px;

  @media (max-width: 815px) {
    margin-top: 96px;
  }

  img {
    margin-bottom: 18px;
  }

  h2 {
    font-family: $rounded-mplus-font-family;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: $main-text-color;

    margin-bottom: 40px;

    @media (max-width: 576px) {
      font-size: 18px;
      line-height: 26px;
    }
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    width: 306px;

    button {
      width: 138px;
    }
  }
}

.modal-scrolled {
  width: 778px;
  padding: 0 70px 53px;
  overflow-y: auto;
  height: 801px;
  max-height: 85vh;
  margin-top: 62px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 815px) {
    width: 100%;
    margin-top: 76px;
    padding: 0 24px 53px;
  }
  @media (max-width: 375px) {
    padding: 0 16px 53px;
  }

  .close-modal-button {
    margin-top: 37px;

    @media (max-width: 815px) {
      width: 340px;
      margin-top: 47px;
    }
    @media (max-width: 341px) {
      width: 90%;
    }
  }
}

.social-network-modal {
  color: #FE8496;
  text-decoration: underline;
}

.social-network-modal:hover {
  color: #FE8496;
  opacity: 0.5;
}

.social-network-modal:visited {
  color: #FE8496;
  opacity: 0.5;
}

.personal-data-modal {
  h2 {
    font-family: $rounded-mplus-font-family;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: $main-text-color;

    width: 400px;
    margin-bottom: 33px;

    @media (max-width: 815px) {
      margin-top: 35px;
      font-size: 18px;
      line-height: 26px;
      width: 335px;
    }
    @media (max-width: 336px) {
      width: 80%;
    }

  }

  div {
    font-family: $futura-pt-font-family;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: $main-text-color;

    ul li {
      display: list-item;
    }
  }

  &__list {
    align-self: flex-start;
  }
}

.faq-modal {
  h2 {
    font-family: $rounded-mplus-font-family;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: $main-text-color;

    margin-bottom: 35px;

    @media (max-width: 815px) {
      font-size: 18px;
      line-height: 26px;
      margin-top: 50px;
      margin-bottom: 45px;
    }
    @media (max-width: 376px) {
      width: 75%;
      margin-bottom: 30px;
    }
  }

  .delivery_info{
    margin-bottom: 10px;

    p {
      margin: 0;
      margin-bottom: 26px;

      font-family: $futura-pt-font-family;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;

      color: $main-text-color;


      span {
        color: #FE8496;;
      }
    }
  }

  &__question {
    width: 636px;
    background: #FFFFFF;
    border-radius: 15px;

    @media (max-width: 815px) {
      width: 100%;
    }

    &__header {
      background: #FFF7DB;
      border-radius: 15px;
      padding: 25px 48px 26px 41px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (min-width: 361px) and (max-width: 525px) {
        padding: 25px 22px 26px 20px;

      }

      @media (max-width: 360px) {
        padding: 25px 15px 26px 20px;
      }

      h3 {
        font-family: $futura-pt-font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 34px;
        color: $main-text-color;

        margin: 0;
      }

      img {
        transform: rotate(360deg);
        transition: transform 0.5s;
      }

      .rotate-arrow {
        animation: activate 0.5s;
        transform: rotate(180deg);
      }

      @keyframes activate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(180deg);
        }
      }
    }

    p {
      padding: 23px 48px 36px 41px;
      margin: 0;
      text-align: justify;

      font-family: $futura-pt-font-family;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;

      color: $main-text-color;

      @media (max-width: 815px) {
        padding: 21px 31px 36px 31px;
      }

      &:not(:last-child) {
        padding-bottom: 0;
      }

    }

    &.active {
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    }

    &:not(:last-of-type) {
      margin-bottom: 18px;
    }
  }

  .delivery__wrapper {
    display: flex;
    font-family: $futura-pt-font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 34px;
    color: #363636;
    justify-content: space-between;
    align-items: center;
    width: 84%;
    border-bottom: 1px dashed #EBEBEB;
    margin: 0 auto;
    padding-top: 25px;
    padding-bottom: 15px;

    @media (max-width: 620px) {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding-right: 35px;
      padding-left: 20px;
    }

    @media (max-width: 400px) {
      padding-right: 15px;
    }

    .delivery-time {
      width: 55%;
    }

    .delivery-region {
      @media (max-width: 620px) {
        font-weight: bold;
        line-height: 28px;
      }
    }

    .delivery__city-info {
      display: flex;
      align-items: center;
      width: 54%;
      justify-content: space-between;

      @media (max-width: 620px) {
        padding-top: 10px;
        width: 100%;
      }
    }
  }

  .delivery__wrapper:last-child {
    padding-bottom: 25px;
  }

  .additional-info {
    margin-bottom: 23px;

    font-family: $futura-pt-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

    color: $main-text-color;
    width: 100%;

    span {
      color: #FE8496;;
    }
  }

  .additional-info.region-info {
    margin-top: 46px;
  }
}

.ordering-modal{
  padding: 47px 90px;

  @media (max-width: 767px) {
    padding: 20px;
  }
  img{
    margin-bottom: 24px;
  }


  .modal-title{
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 28px;
  }
  .modal-info{
    font-family: 'Futura PT', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    max-width: 330px;
    text-align: center;
    margin-bottom: 40px;
  }

  .ordering-modal__controls{
    gap: 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 767px) {
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  button{
    white-space: nowrap;
    min-width:190px;
    width: fit-content;
    padding: 18px 22px;
    font-size: 16px;
    line-height: 20px;

    @media (max-width: 767px) {
      width: 100%;
      max-width: 340px;
    }
    @media (max-width: 360px) {
      max-width: 90%;
    }
  }
  .btn-pink{
    @media (max-width: 767px) {
      order: 1;
    }
  }

  .btn-green{
    @media (max-width: 767px) {
      order: 0;
    }
  }
}
.modal.modal-are-you-sure{
  z-index: 100000;
}