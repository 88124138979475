.checkBoxContainer{
    position: relative;
}
.checkBoxContainer *{
    box-sizing: border-box;
}

.checkboxInput{
    position: absolute;
    visibility: hidden;
    left: 8px;
    top: 6px;
    width: 40px;
    height: 40px;
}

.checkbox{
    user-select: none;
    cursor: pointer;
    // width:40px;
    width: max-content;
    padding: 0;
    //padding: 6px 8px;
    border-radius: 6px;
    //overflow: hidden;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    margin-top: 6px;
    position: relative;
}

checkbox:not(:last-child) {
    margin-right: 6px;
}

.checkbox span {
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
}

.checkbox span:first-child {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: 2px solid #E5E5E5;
    transition: all 0.3s ease;
}

.checkbox span:first-child svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.checkbox span:last-child {
    //padding-left: 20px;
    line-height: 18px;
}

.checkbox:hover span:first-child {
    border-color: #69C9A6;
    cursor: pointer;
}

.checkboxInput:checked + .checkbox span:first-child {
    background: #69C9A6;
    border-color: #69C9A6;
}
.checkboxLabelInfo{
    font-weight: 400;
    font-size: 16px;
    line-height: 20.51px;
    color: #363636;
    // position: absolute;
    margin-left: 20px;
    white-space: nowrap;
    left: 60px;
    padding: 0;
}
