i {
  display: block;

  &.white-arrow-right {
    margin-left: 20px;
    background: url("../images/white-arrow-right.svg") no-repeat 0 0;
    width: 20px;
    height: 11.5px;
  }

  &.white-arrow-right.book-step {
    margin-left: 14px;
  }

  &.white-arrow-left {
    background: url("../images/btn-arrow-left.svg") no-repeat 0 0;
    width: 20px;
    height: 11.5px;
    margin-right: 15px;
    margin-top: 5px;
  }

  &.white-arrow-left.book-step {
    background: url("../images/book-step-arrow-left.svg") no-repeat 0 0;
    height: 16.5px;
    margin-left: 14px;
  }
}

.book__pagination {
  position: absolute;
  bottom: -67px;
  left: 43%;

  font-family: $rounded-mplus-font-family;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 30px;

  letter-spacing: 0.1em;
  color: rgba(54, 54, 54, 0.68);

  width: 100px;
  display: flex;
  justify-content: center;


  &__active {
    color: #000000;
    font-size: 31px;

    @media (max-width: 812px){
      font-size: 24px;
      line-height: 19px;
    }

    &~div{
      @media (max-width: 812px){
        font-size: 16px;
        line-height: 1;
      }
    }
  }
}

.the-book-step {
  margin-top: 61px;
  margin-bottom: 70px;
  position: relative;

  @media (max-width: 1250px) {
    width: 100%;
    position: inherit;
    top: auto;
    left: auto;

    margin-top: 50px;
    margin-bottom: 30px;
  }

  @media (max-width: 812px) {
   margin-top: 0;
  }

  @media (min-width: 1101px) and (max-width: 1399px) {
    //top: 0px;
  }

  .demo-book {
    left: 0;
    transition: left .7s;
    z-index: 20;

    @media (min-width: 1101px) and (max-width: 1399px) {
    //  margin-bottom: 30px;
    }

    &.stf__parent {
      width: 1038px !important;
    }

    @media (max-width: 1250px) {
      display: none !important;
    }

    &.cover.stf__parent {
      transform-style: preserve-3d;
      left: -22%;
      transition: left .7s;
      z-index: 20;
      @media (min-width: 1101px) and (max-width: 1399px) {
        //left: -25%;
      }
      .page.--right .page-content img {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }
    }

    .stf__wrapper {
      padding: 0 !important;
    }
  }

  .page-content, .img-wrapper, .img-wrapper img, .page-content img {
    width: 100%;
    height: 100%;
  }

  .page-content{
    @media (min-width: 1101px) and (max-width: 1399px) {
      //height: 250px;
      //width: 383px;
    }
  }

  .stf__block {
    filter: drop-shadow(0px 10px 50px rgba(0, 0, 0, 0.25));
  }

  .container {
    z-index: 10;

    @media (max-width: 1250px) {
      display: none;
    }
  }

  button, .book-slider-btn.prev, .book-slider-btn.next {
    cursor: pointer;
    background: #FE8496;
    border-radius: 35px;
    color: white;
    width: 44px;
    height: 44px;
    box-shadow: none;
    padding: 0;
    min-width: 44px;
    transition: all 0.3s ease;
    font-size: 1.6em;
    border: none;
    line-height: 50px;
    align-self: center;
    outline-color: transparent;
    font-family: $futura-pt-font-family;
    font-style: normal;
    font-weight: bold;
    margin: 0 26px;
  }

  button:disabled, .book-slider-btn.next:disabled, .book-slider-btn.prev:disabled {
    cursor: not-allowed;
    background: #DDDDDD;
    border-radius: 35px;
    color: white;
    width: 44px;
    height: 44px;
    box-shadow: none;
    padding: 0;
    min-width: 44px;
    transition: all 0.3s ease;
    font-size: 1.6em;
    border: none;
    line-height: 50px;
    align-self: center;
    outline: none;
    font-family: $futura-pt-font-family;
    font-style: normal;
    font-weight: bold;
  }

  .book-controls-desktop {
    display: none;

    @media (min-width: 1250px) {
      display: block;
      width: 1225px;
      height: 44px;
      position: absolute;
      left: -94px;
      top: 45%;
      transition: 1s;

      &.cover-page {
        width: 715px;
        left: 190px;
        transition: 1s;
      }

      .right-arrow {
        position: absolute;
        right: 0;
        &:active{
          box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.25);
        }

      }

      button:hover {
        box-shadow: 0 10px 20px $pink-color;
      }
      button:active{
        box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.25);
      }
    }

    @media (min-width: 1101px) and (max-width: 1399px) {
      //width: 956px;
      //&.cover-page {
      //  width: 558px;
      //  left: 105px;
      //  transition: 1s;
      //}
    }
  }
}

.the-book-step.book-cover-step{
  //top:25px;
}

.jaGAIm.rec.rec-slider-container {
  margin: 0;
}

.book-slider-btn {
  position: absolute;
  bottom: -22%;
  z-index: 2;
}

.book-slider-btn.prev {
  bottom: -71px;
  left: 21%;
  cursor: pointer;

  &:after {
    left: 36%;
    transform: rotate(180deg);
  }
}

.book-slider-btn.next {
  bottom: -71px;
  left: 57%;
  cursor: pointer;
  transform: rotate(180deg);

  &:after {
    top: 29%;
    left: 31%;
  }
}

.page.--left .page-content img {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.page.--right .page-content img {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.book-mobile-slider {
  display: none;

  @media (max-width: 1249px) {
    display: block;

    .menu-item {
      user-select: none;
      cursor: pointer;
      border: 1px transparent solid;
      width: 70vw;

      @media (max-width: 400px) {
        width: 90vw;
      }
    }

    .menu-item-wrapper {
      border: 1px transparent solid;
      outline: 1px transparent solid;
    }

    .scroll-menu-arrow {
      padding: 20px;
      cursor: pointer;
    }

    .scroll-menu-arrow--disabled {
      visibility: hidden;
    }

    .menu-item img {
      width: 100%;
      border-radius: 5px;
    }

    .menu-wrapper--inner {
      width: 10000px !important;
      white-space: nowrap !important;
      //transform: translate3d(2px, 0px, 0px) !important;

    }

    &__controls {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 29px;

      .book-slider-btn.prev {
        position: relative;
        left: 0;
        bottom: 0;
      }

      .book__pagination {
        position: relative;
        left: 0;
        bottom: 0;

        @media (max-width: 812px){
          align-items: flex-end;
        }
      }

      .book-slider-btn.next {
        position: relative;
        left: 0;
        bottom: 0;
      }
    }
  }
}

@media (max-width: 812px){
  .book-mobile-slider__controls{
    margin-top: 25px;
  }

  .book-mobile-slider__controls .book-slider-btn.prev, .book-mobile-slider__controls .book-slider-btn.next{
    margin: 0;
  }
}


