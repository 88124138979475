.covers {
  display: flex;


  .cover {
    border: 2px dashed $dash-color;
    border-radius: 10px;
    width: 476px;
    height: 434px;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0 51px;
    cursor: pointer;

    &:hover {
      background: $background-active-color;
      border: 2px dashed $pink-color;
    }

    &:nth-child(1) {
      margin-right: 30px;
    }

    h2 {
      font-style: normal;
      font-weight: 900;
      font-size: 22px;
      line-height: 54px;

      color: $main-text-color;
    }

    img {
      width: 374px;
      height: 264px;
      border-radius: 10px;
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 27px;

      span {
        font-family: $rounded-mplus-font-family;
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 46px;
        color: $main-text-color;
      }

      &__colours {
        display: flex;
        align-items: center;

        span {
          font-family: $futura-pt-font-family;
          font-weight: 500;
          font-size: 16px;
          line-height: 28px;
        }

      }
    }


  }
}


.dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;

  &:not(:first-child) {
    margin-left: 10px;
  }

  &_yellow {
    background-color: #F2C430;
  }

  &_blue {
    background-color: #48C8F0;
  }

  &_pink {
    background-color: #FE8496;
  }

  &_green {
    background-color: #77BDAB;
  }

  &_dashed {
    height: 31px;
    width: 31px;
    border-radius: 50%;
    border: 1px dashed #363636;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: 13px;
  }

  &-bigger {
    height: 33px;
    width: 33px;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 15px;

    &_dashed {
      height: 41px;
      width: 41px;
      border-radius: 50%;
      border: 2px dashed #363636;
      display: flex;
      align-items: center;
      justify-content: center;

      margin-right: 11px;

      .dot-bigger {
        margin: 0;
      }
    }
  }
}

.cover-is-chosen {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 31px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    max-width: 617px;
    @media (min-width: 1101px) and (max-width: 1399px) {
      margin-bottom: 20px;
    }

    @media (max-width: 767px) {
      flex-direction: column-reverse;
      margin-bottom: 5px;
    }

    &__colours {
      width: 250px;
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        margin-top: 20px;
        width: 218px;
      }

      span {
        margin-right: 25px;
        font-size: 24px;
        font-weight: 500;
        font-family: 'Futura PT';
      }
    }
  }

  &__img {
    width: 519px;
    height: 376px;

    filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.15));
    border-radius: 15px;

    margin: 25px 0 50px;

    z-index: 0;
  }

  .see-the-book__toggler .toggle-button:checked::after,
  .see-the-book__toggler .toggle-button::after {
    background-color: #69C9A6;
    color: #ffffff;
    border: none;
 
  }


  .see-the-book__toggler .toggle-button:checked::after{
    transform: translateX(calc(100%));
  }

  .see-the-book__toggler .cover-is-chosen__toggler {

    &.active > span {
      color: white;
      opacity: 1;
    }

    > span {
      width: 150px;
    }

    &__first-line {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;

      left: 11px;
      top: -6px !important;
    }

    &__second-line {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;

      left: 34px;
      top: 15px !important;
    }

    &__hard-first-line {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;

      right: -45px;
      top: -6px !important;
    }

    &__hard-second-line {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;

      right: -67px;
      top: 15px !important;
    }
  }
}

.btns-block.cover{
  margin-top: 63px;
}

.gender_section__info__toggler.see-the-book__toggler{
  @media (max-width: 812px) {
    margin-top: -10px;
  }
}

.cover-is-chosen__header__colours span{
  @media (max-width: 812px){
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }
}

.btns-block.book-mobile.cover{
  @media (max-width: 812px){
    margin-top: 4px;
    margin-bottom: 126px;
  }
}

.gender_section__info__toggler.see-the-book__toggler.cover{
  input.toggle-button{
    @media (min-width: 816px) and (max-width: 1200px){
      width: 340px;
    }
  }
}

.gender_section__content{
  .the-book-step.book-cover-step{
    margin-top: 25px;
    margin-bottom: 0;
  }
  .btns-block.book-mobile.cover{
    width: 100%;
    max-width: 522px;
    justify-content: flex-end;
    margin-top: 40px;
    margin-bottom: 70px;

    @media (max-width: 1250px){
      max-width: 503px;
    }

    @media (max-width: 812px){
      justify-content: center;
      margin-bottom: 124px;
    }

    .btn-icon{
      @media (max-width: 812px){
        justify-content: space-around;
      }

      &.btn-green{
        margin-left: 19px;
        height: 59px;

        .white-arrow-right{
          margin-left: 4px;
          @media (max-width: 767px){
            margin-left: 0;
          }
        }
      }
    }

  }
}

