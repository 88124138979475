p{
    margin: 0;
}

.yourOrderWrapper{
    padding:20px 0;
    width: 100%;
}
@media (max-width: 975px) {
    .yourOrderWrapper{
        width:95%;
        margin: 0 auto;
    }
}

@media (max-width: 500px) {
    .yourOrderWrapper{
        width: 92%;
    }
}

.orderItem{
    display: grid;
    grid-template-columns: 191px 36% 24% auto;
    column-gap: 3%;

    box-shadow: 0px 0px 40px rgba(0,0, 0, 0.1);
    border-radius: 15px;
    padding: 23px;
    padding-bottom: 18px;
    }


.imgWrapper{
    max-width: 191px;
    min-width: 100px;
    display: flex;
    border-radius: 15px;
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.15));
}
.coverImg{
    min-width:100%;
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 15px;
}

.orderItemInfo{
    display: grid;
    align-items: center;
    margin-top: 4px;

    @media (max-width: 500px) {
        margin-top: 0;
    }
}

.productName,.productAdditionalInfo{
    font-family: 'Futura PT', sans-serif;
    color:#727272;
}

.productName{
    margin-bottom: 8px;

    @media (max-width: 500px) {
        margin-bottom: 0px;
    }
}
.productTitle{
    font-family: 'Futura PT', sans-serif;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 0;
}

.productName,.productAdditionalInfo{
    font-family: 'Futura PT', sans-serif;
    color:#727272;
}

.productAdditionalInfo, .counter{
    display: flex;
    align-items: flex-end;
    padding-bottom: 15px;
}

.productAdditionalInfo{
    margin-top: 36px;
}

.priceBlock{
    border-left: 2px solid #EBEBEB;
    border-right: 2px solid #EBEBEB;
    overflow: hidden;
    @media (max-width: 975px) {
        overflow: inherit;
    }
}

.priceInfoWrapper {
    width: max-content;
    max-width:221px;
    margin: 0 auto;
    height: 100%;
    padding: 0 20px;
    margin-top: 3px;
}

.price{
    margin-bottom: 28px;
    overflow: hidden;
}

.counterMinus,.counterIncrease{
    position: relative;
    width: 30px;
    height: 30px;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    padding:0 ;
    background-color: white;
    color: #FE8496;
}

.counterMinus:hover, .counterIncrease:hover{
    cursor: pointer;
    border-color: #FE8496;
}


.counterMinus svg,.counterIncrease svg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.counterMinus.disabled{
    pointer-events: none;
    opacity: 0.5;
}

.price span:nth-child(2){
    font-weight: 700;
    font-family: 'Futura PT', sans-serif;
    font-size: 14px;
}

.counter{
    display: flex;
    align-items: center;

    @media (max-width: 500px) {
        margin-top: -5px;
    }
}
.count{
    min-width: 31px;
    width: max-content;
    text-align: center;
}

.removeBlock{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
}

.removeBlock:hover{
    cursor: pointer;
    opacity: 0.8;
}

@media (max-width: 975px){
    .orderItem{
        grid-template-columns:38% auto 50px;
        row-gap: 20px;
    }

    .priceBlock{
        grid-row-start: 2;
        grid-column-start: 1;
        grid-column-end: 3;
        border: none;
    }
    .priceBlock .productName, .removeBlock .productName{
       display: none;
    }

    .priceInfoWrapper{
        display: grid;
        grid-template-columns: 44% auto;
        margin-left: 0;
        width: 100%;
        max-width: 100%;
        padding-left: 0;
    }
    .price{
        order:1;
        margin-bottom: 0;
        padding-left: 20px;
        /*max-width: 100%;*/

    }
    .counter{
        order:0;
        justify-content: center;
        padding-bottom: 0;
    }

    .removeBlock{
        grid-row-start: 2;
    }
}

@media (max-width: 767px){
    .orderItem{
        grid-template-columns:100px auto 50px;
    }

    .orderItemInfo .productName, .orderItemInfo .productTitle{
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        color: #363636;
    }
    .orderItemInfo .productTitle{
        margin-bottom: 10px;
    }

    .priceInfoWrapper{
        grid-template-columns: 100px auto;
    }

    .price .productTitle{
        font-size: 18px;
        line-height: 23px;
    }

    .productAdditionalInfo{
        margin-top: 0;
        padding-bottom: 0;
    }
}
@media (max-width: 500px) {
    .orderItem{
        grid-template-columns: 100px auto 21px;
        padding: 20px 14px 16px 14px;
        row-gap: 14px;
        column-gap: 17px;
    }
}

@media (max-width: 350px){
    .yourOrderWrapper{
        width: 85%;
    }
}

