.wishstep {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 73px;

  @media (max-width: 815px) {
    width: 100%;
    margin-top: 0;
  }

  &__main {
    display: flex;
    justify-content: space-between;
    align-items: center;

    min-width: 1061px;
    margin-bottom: 41px;
    margin-top: -30px;

    @media (max-width: 1100px) {
      flex-direction: column;
      padding: 30px 20px 0 20px;
      min-width: auto;
      width: 100%;
      margin-bottom: 15px;
    }

    .header-block-title.constructor {
      @media (max-width: 825px) {
        margin: 0 0 43px;
      }
    }

    &__input {
      border: 2px solid #E5E5E5;
      box-sizing: border-box;
      border-radius: 35px;
      height: 100%;
      width: 404px;
      min-height: 426px;
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 48px 36px 85px 47px;

      position: relative;

      @media (max-width: 1100px) {
        width: 100%;
        height: 312px;
        margin-bottom: 60px;
        padding: 49px 19px 66px 22px;
        min-height: auto;
      }

      &__settings {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 8px 44px 9px 23px;

        position: absolute;
        top: -20px;

        background: #FFF7DB;
        border-radius: 30px;

        width: 321px;

        span {
          font-family: $futura-pt-font-family;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;

          color: $main-text-color;

          opacity: 0.7;
        }
      }

      textarea {
        resize: none;
        border: none;
        outline: none;

        width: 100%;
        height: 100%;

        font-family: $futura-pt-font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        text-align: center;

        color: $main-text-color;

        opacity: 0.7;

        @media (max-width: 815px) {
          font-size: 14px;
          line-height: 21px;
        }
      }

      &__accept {
        height: 60px;
        position: absolute;
        bottom: -30px;
      }

      &.error {
        border: 2px solid $pink-color;
      }
    }

    &__result {
      img {
        width: 622px;
        height: 444px;
        border-radius: 35px;

        @media (max-width: 815px) {
          width: 100%;
          height: auto;
        }
      }

      @media (max-width: 815px) {
        width: 100%;
        min-height: 250px;
      }
    }
  }
}
.wishstep__main__input__accept{
  .white-arrow-right{
    @media (max-width: 812px) {
      display: none;
    }
  }
  @media (max-width: 812px) {
    padding: 17px 38px;
    bottom: -20px;
    height: auto;
  }
}
