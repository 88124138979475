$futura-pt-font-family: 'Futura PT', sans-serif;
$rounded-mplus-font-family: 'Rounded Mplus 1c', sans-serif;
$montserrat:'Montserrat',sans-serif;
$main-text-color: #363636;
$pink-color: #FE8496;
$button-blue-color: #48C8F0;
$dash-color: #E5E5E5;
$background-active-color: #FFF7DB;
@mixin header-title{
  font-family:$rounded-mplus-font-family;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  color: $main-text-color;
}
$header-height-desktop: 89px;
$header-height-mobile: 71px;
